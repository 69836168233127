import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { NgModalConfirm } from '../../shared/modal';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../services/index';

import { User } from '../../models/user.model';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-firstresponder-form',
  styleUrls: ['./firstresponder-form.component.scss'],
  templateUrl: './firstresponder-form.component.html',
})
export class FirstresponderFormComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private modalService: NgbModal
  ) { }

  public isNewUser = false;
  model: User = new User();
  modalRef;
  yes;

  submitted = false;

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) =>
          params.id == 'new'
            ? Observable.throw(null)
            : this.api.getFirstresponder(params.id)
        )
      )
      .pipe(
        catchError((e) => {
          // new defi
          this.isNewUser = true;
          this.model = new User();
          return EMPTY;
        })
      )
      .subscribe((user) => {
        // existing defi from service
        this.isNewUser = false;
        this.model = new User().deserialize(user);
      });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // send changed to API and redirect back
    if (this.isNewUser) {
      // post new responder
      this.api.postFirstresponder(this.model).subscribe((response) => {
        this.router.navigate(['firstresponderadmin']);
      });
    } else {
      // put existing responder
      this.api.putFirstresponder(this.model).subscribe((response) => {
        this.router.navigate(['firstresponderadmin']);
      });
    }
  }

  confirmDelete() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(NgModalConfirm, ngbModalOptions);
    modalRef.result.then(
      (result) => {
        this.deleteUser();
      },
      () => { }
    );
  }

  deleteUser() {
    if (!this.isNewUser) {
      // set the form to submitted to avoid double-firing
      this.submitted = true;

      // delete existing defi
      this.api.deleteFirstresponder(this.model).subscribe((response) => {
        this.router.navigate(['firstresponderadmin']);
      });
    }
  }
}
