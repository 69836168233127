<div class="container">
    <div class="row">
        <div class="col-12">
            <h1 [hidden]="isNewUser">User details</h1>
            <h1 [hidden]="!isNewUser">New User</h1>
            <button class="btn btn-danger float-right" (click)="confirmDelete()"
                [style.display]="!isNewUser?'inherit':'none'" ng-really-message="Are you sure?">Delete User</button>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="firstName">First name</label>
                    <input type="text" class="form-control" id="firstName" required [(ngModel)]="model.firstName"
                        name="firstName">
                </div>
                <!--<div class="form-group">
                    <label for="middleName">Middle name</label>
                    <input type="text" class="form-control" id="middleName"
                           [(ngModel)]="model.middleName" name="middleName">
                </div>-->
                <div class="form-group">
                    <label for="lastName">Last name</label>
                    <input type="text" class="form-control" id="lastName" required [(ngModel)]="model.lastName"
                        name="lastName">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" required [(ngModel)]="model.email" name="email">
                </div>
                <!--<div class="form-group">
                    <label for="phoneNumber">Phone</label>
                    <input type="text" class="form-control" id="phoneNumber"
                           [(ngModel)]="model.phoneNumber" name="phoneNumber">
                </div>-->
                <div class="form-group">
                    <label for="superAdmin">Super admin</label>
                    <input type="checkbox" class="form-control" id="superAdmin" [(ngModel)]="model.roleSuperAdmin"
                        name="superAdmin">
                </div>
                <div class="form-group">
                    <label for="countryAdmin">Country admin</label>
                    <input type="checkbox" class="form-control" id="countryAdmin" [(ngModel)]="model.roleCountryAdmin"
                        name="countryAdmin">
                </div>
                <div class="form-group">
                    <label for="countryAdmin">Partner admin</label>
                    <input type="checkbox" class="form-control" id="partnerAdmin" [(ngModel)]="model.rolePartnerAdmin"
                        name="partnerAdmin">
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Countries</label>
                    <div class="input-group country"
                        *ngFor="let country of model.adminCountries; let i = index; trackBy:trackByFn">
                        <select class="form-control" [(ngModel)]="model.adminCountries[i]" id="adminCountries-{{i}}"
                            name="adminCountries-{{i}}">
                            <option *ngFor="let c of countries" [value]="c.value">{{c.name}}</option>
                        </select>
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-danger pull-right equal" (click)="removeCountry(i)"><i
                                    class="fa fa-minus-circle" aria-hidden="true"></i>Remove</button>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <button type="button" class="btn btn-primary pull-left" (click)="addCountry()"><i
                            class="fa fa-plus-circle" aria-hidden="true"></i> Add Country</button>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Partner</label>
                    <div class="input-group country"
                        *ngFor="let partner of model.adminPartners; let i = index; trackBy:trackByFn">
                        <select class="form-control" [(ngModel)]="model.adminPartners[i]" id="adminPartners-{{i}}"
                            name="adminPartners-{{i}}">
                            <option *ngFor="let c of partners" [value]="c">{{c}}</option>
                        </select>
                        <span class="input-group-btn">
                            <button type="button" class="btn btn-danger pull-right equal" (click)="removePartner(i)"><i
                                    class="fa fa-minus-circle" aria-hidden="true"></i>Remove</button>
                        </span>
                    </div>
                </div>
                <div class="form-group">
                    <button type="button" class="btn btn-primary pull-left" (click)="addPartner()"><i
                            class="fa fa-plus-circle" aria-hidden="true"></i> Add Partner</button>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right" style="margin-top: 20px;">
                <button type="button" class="btn btn-secondary" [routerLink]="['/useradmin']">Cancel</button>
                <button type="submit" class="btn btn-success"
                    [disabled]="!userForm.form.valid || this.submitted">Save</button>
            </div>
        </div>
    </form>
</div>