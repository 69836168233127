import { PlaceCategoryList } from "./place-category.model";
import { Defi, Place, Address, Location, MediaFile } from "./defi.model";

interface Serializable<T> {
    deserialize(input: Object): T;
}

export class RegistrationRequest implements Serializable<RegistrationRequest> {
    public id: string;
    public requestId: number;
    public submitted: Date;
    public ipAddress: string;

    // new defi
    public locationDescription: string;
    public locationLatitute?: number;
    public locationLongitude?: number;

    // new place
    public googlePlaceId: string;
    public placeName: string;
    public placeType: number;
    public alwaysOpen: boolean;
    public contactTitle: string;
    public contactFirstName: string;
    public contactLastName: string;
    public phone1: string;
    public phone2: string;
    public mobile: string;
    public fax: string;
    public email: string;
    public website: string;

    // new address
    public addressLocationLatitute?: number;
    public addressLocationLongitude?: number;
    public street: string;
    public street2: string;
    public zipCode: string;
    public city: string;
    public state: string;
    public country: string;

    public mediaFiles: Array<MediaFile>;


    // auto property
    get placeTypeName(): string {
        const place = PlaceCategoryList.getCategoryList()[this.placeType];
        if (!place)
            return "unknown";
        return place.name;
    }

    get newDefi(): Defi {
        // create defi
        let defi = new Defi();
        defi.locationDescription = this.locationDescription;
        defi.alwaysOpen = this.alwaysOpen;
        if (this.locationLatitute && this.locationLongitude)
        {
            defi.location = new Location();
            defi.location.latitude = this.locationLatitute;
            defi.location.longitude = this.locationLongitude;
        }

        // create place
        defi.place = new Place();
        defi.place.googlePlaceId = this.googlePlaceId;
        defi.place.name = this.placeName;
        defi.place.type = this.placeType;
        defi.place.contactTitle = this.contactTitle;
        defi.place.contactFirstName = this.contactFirstName;
        defi.place.contactLastName = this.contactLastName;
        defi.place.phone1 = this.phone1;
        defi.place.phone2 = this.phone2;
        defi.place.mobile = this.mobile;
        defi.place.fax = this.fax;
        defi.place.email = this.email;
        defi.place.website = this.website;

        // create address
        defi.place.address = new Address();
        defi.place.address.street = this.street;
        defi.place.address.street2 = this.street2;
        defi.place.address.zipCode = this.zipCode;
        defi.place.address.city = this.city;
        defi.place.address.state = this.state;
        defi.place.address.country = this.country;
        // address location
        if (this.addressLocationLatitute && this.addressLocationLongitude) {
            defi.place.address.location = new Location();
            defi.place.address.location.latitude = this.addressLocationLatitute;
            defi.place.address.location.longitude = this.addressLocationLongitude;
        }

        // image
        defi.mediaFiles = this.mediaFiles;
        
        // ready
        return defi;
    }

    constructor() {
    }

    deserialize(input) {
        this.id = input.id;
        this.requestId = input.requestId;
        this.submitted = input.submitted;
        this.ipAddress = input.ipAddress;

        // new defi
        this.locationDescription = input.locationDescription;

        // new place
        this.placeName = input.placeName;
        this.placeType = input.placeType;
        this.alwaysOpen = input.alwaysOpen;
        this.contactTitle = input.contactTitle;
        this.contactFirstName = input.contactFirstName;
        this.contactLastName = input.contactLastName;
        this.phone1 = input.phone1;
        this.phone2 = input.phone2;
        this.mobile = input.mobile;
        this.fax = input.fax;
        this.email = input.email;
        this.website = input.website;

        // new address
        this.street = input.street;
        this.street2 = input.street2;
        this.zipCode = input.zipCode;
        this.city = input.city;
        this.state = input.state;
        this.country = input.country;


        // new mediaFiles 
        this.mediaFiles = new Array<MediaFile>();
        for (let entry of input.mediaFiles) {
            this.mediaFiles.push(new MediaFile().deserialize(entry));
        }

        return this;
    }
}
