<div class="container">
    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
        <div class="row">
            <div class="col-12">
                <h1 [hidden]="isNewUser">User details</h1>
                <h1 [hidden]="!isNewUser">New User</h1>
                <button class="btn btn-danger float-right" (click)="confirmDelete()"
                    [style.display]="!isNewUser?'inherit':'none'" ng-really-message="Are you sure?">Delete User</button>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="firstName">First name</label>
                    <input type="text" class="form-control" id="firstName" readonly [(ngModel)]="model.firstName"
                        name="firstName">
                </div>
                <div class="form-group">
                    <label for="lastName">Last name</label>
                    <input type="text" class="form-control" id="lastName" readonly [(ngModel)]="model.lastName"
                        name="lastName">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" [required]="isNewUser" [readonly]="!isNewUser"
                        [(ngModel)]="model.email" name="email">
                </div>

                <div class="form-group">
                    <label for="firstAider">First responder</label>
                    <input type="checkbox" class="form-control" id="firstAider" [(ngModel)]="model.firstAider"
                        name="firstAider">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right" style="margin-top: 20px;">
                <button type="button" class="btn btn-secondary" [routerLink]="['/firstresponderadmin']">Cancel</button>
                <button type="submit" class="btn btn-success"
                    [disabled]="!userForm.form.valid || this.submitted">Save</button>
            </div>
        </div>
    </form>
</div>