import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/index';
import { User } from '../../models/user.model';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-firstresponder-list',
  styleUrls: ['../defis/defi-list.component.scss'],
  templateUrl: './firstresponder-list.component.html',
})
export class FirstresponderListComponent implements OnInit {
  // font awesome
  public faEdit = faEdit;

  // loading indicator
  loading = false;

  @ViewChild('editItemTmpl', { static: true }) editItemTmpl: TemplateRef<any>;

  // column definition
  columns;

  data: Array<User>;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.columns = [
      { prop: 'name', name: 'Name', width: 200 },
      { prop: 'email', name: 'Email', width: 200 },
      { name: 'Actions', cellTemplate: this.editItemTmpl, width: 20 },
    ];

    // indicate loading
    this.loading = true;

    // load data
    this.loadData();
  }

  private loadData() {
    // load data (take, skip)
    this.api.getFirstresponders().subscribe((response) => {
      // store the data
      this.data = new Array<User>();
      for (const entry of response) {
        this.data.push(new User().deserialize(entry));
      }

      // loading finished
      this.loading = false;
    });
  }
}
