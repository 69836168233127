<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>Image Request - Defibrillator details</h1>
        </div>
    </div>
    <form (ngSubmit)="onSubmit()" #defiForm="ngForm">
        <div class="row">
            <div class="col-6">
                <p [hidden]="!model.place.typeName"><span>Place category: </span>{{model.place.typeName}}</p>
                <p [hidden]="!model.place.name"><span>Name: </span>{{model.place.name}}</p>
                <p [hidden]="!model.place.address.street"><span>Street: </span>{{model.place.address.street}}</p>
                <p [hidden]="!model.place.address.street2"><span>Street 2: </span>{{model.place.address.street2}}</p>
                <p [hidden]="!model.place.address.zipCode"><span>Zip code: </span>{{model.place.address.zipCode}}</p>
                <p [hidden]="!model.place.address.city"><span>City: </span>{{model.place.address.city}}</p>
                <p [hidden]="!model.locationDescription"><span>Floor/Room/Place name:
                    </span>{{model.locationDescription}}</p>
                <p [hidden]="!model.place.address.state"><span>State: </span>{{model.place.address.state}}</p>
                <p [hidden]="!model.place.address.country"><span>Country: </span>{{model.place.address.country}}</p>
                <p [hidden]="!model.place.description"><span>Place description: </span>{{model.place.description}}</p>
                <p [hidden]="!model.alwaysOpen"><span>Always open (24h): </span>{{model.alwaysOpen}}</p>
            </div>
            <div class="col-6">
                <p [hidden]="!model.place.contactTitle"><span>Contact title: </span>{{model.place.contactTitle}}</p>
                <p [hidden]="!model.place.contactFirstName"><span>Contact firstname:
                    </span>{{model.place.contactFirstName}}</p>
                <p [hidden]="!model.place.contactLastName"><span>Contact lastname:
                    </span>{{model.place.contactLastName}}</p>
                <p [hidden]="!model.place.phone1"><span>Phone 1: </span>{{model.place.phone1}}</p>
                <p [hidden]="!model.place.phone1"><span>Phone 2: </span>{{model.place.phone1}}</p>
                <p [hidden]="!model.place.mobile"><span>Mobile: </span>{{model.place.mobile}}</p>
                <p [hidden]="!model.place.fax"><span>Fax: </span>{{model.place.fax}}</p>
                <p [hidden]="!model.place.email"><span>Email: </span>{{model.place.email}}</p>
                <p [hidden]="!model.place.website"><span>Website: </span>{{model.place.website}}</p>
            </div>
            <div class="col-12">
                <h3>New image</h3>
                <div *ngIf="image" class="col-md-4 image">
                    <img *ngIf="image" [src]="'data:image/PNG;base64,' + image" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right" style="margin-top: 20px;">
                <button type="button" class="btn btn-secondary" [routerLink]="['/image']">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="ignoreRequest()"
                    ng-really-message="Do you really want to ignore this request?" [disabled]="this.submitted">Ignore
                    image
                    request</button>
                <button type="submit" class="btn btn-success" [disabled]="this.submitted"
                    ng-really-message="Do you really want to delete this defibrillator?">Accept image request</button>
            </div>
        </div>
    </form>
</div>