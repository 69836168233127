<div class="container google">
    <div class="row">
        <div class="col-12">
            <h1 [hidden]="isNewDefi">Defibrillator details</h1>
            <h1 [hidden]="!isNewDefi">New Defibrillator</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12 text-right" *ngIf="!isNewDefi && model.syncPartner === 'Cisali'">
            <button type="button" class="btn btn-danger" (click)="deleteDefi()"
                ng-really-message="Are you sure?">Delete</button>
        </div>
        <div class="col-12 text-right">
            <div class="pull-left google_warning" *ngIf="googlePlace.placeId">
                <div *ngIf="googlePlace.placeId == 'no_address'">
                    <fa-icon [icon]="faExclamationTriangle" aria-hidden="true"></fa-icon>No Google address found!
                </div>
            </div>

            <button type="button" class="btn btn-info" (click)="testLocation()">Test location (Google
                maps)</button>
            <p class="google" *ngIf="googlePlace.placeId">
                Check only shows different values to Google!
            </p>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" #defiForm="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Place category</label>
                    <select class="form-control" id="type" required [(ngModel)]="model.place.type" name="type">
                        <option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="name">Name</label>
                    <input [readOnly]="model.place.type==11" type="text" class="form-control" id="name"
                        [required]="model.place.type!=11" [(ngModel)]="model.place.name" name="name">
                    <p class="google"
                        *ngIf="googlePlace.placeId && model.place.name != googlePlace.placeName && model.place.type!=11">
                        {{googlePlace.placeName}}
                        <a class="" (click)="model.place.name = googlePlace.placeName" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>

                <div class="form-group">
                    <label for="street">Street</label>
                    <input type="text" class="form-control" id="street" required
                        [(ngModel)]="model.place.address.street" name="street">
                    <p class="google" *ngIf="googlePlace.placeId && model.place.address.street != googlePlace.street">
                        {{googlePlace.street}}
                        <a class="" (click)="model.place.address.street = googlePlace.street" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="street2">Street 2</label>
                    <input type="text" class="form-control" id="street2" [(ngModel)]="model.place.address.street2"
                        name="street2">
                </div>
                <div class="form-group">
                    <label for="zipCode">Zip code</label>
                    <input type="text" class="form-control" id="zipCode" required
                        [(ngModel)]="model.place.address.zipCode" name="zipCode">
                    <p class="google" *ngIf="googlePlace.placeId && model.place.address.zipCode != googlePlace.zipCode">
                        {{googlePlace.zipCode}}
                        <a class="" (click)="model.place.address.zipCode = googlePlace.zipCode" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="city">City</label>
                    <input type="text" class="form-control" id="city" required [(ngModel)]="model.place.address.city"
                        name="city">
                    <p class="google" *ngIf="googlePlace.placeId && model.place.address.city != googlePlace.city">
                        {{googlePlace.city}}
                        <a class="" (click)="model.place.address.city = googlePlace.city" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="locationDescription">Floor/Room/Place name</label>
                    <input type="text" id="locationDescription" class="form-control"
                        [(ngModel)]="model.locationDescription" name="locationDescription">
                </div>
                <div class="form-group">
                    <label for="state">State</label>
                    <input type="text" class="form-control" id="state" [(ngModel)]="model.place.address.state"
                        name="state">
                    <p class="google" *ngIf="googlePlace.placeId && model.place.address.state != googlePlace.state">
                        {{googlePlace.state}}
                        <a class="" (click)="model.place.address.state = googlePlace.state" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="country">Country</label>
                    <!--<input type="text" class="form-control" id="country"
                    [(ngModel)]="model.place.address.country" name="country">-->
                    <select class="form-control" [(ngModel)]="model.place.address.country" id="country" name="country"
                        required>
                        <option *ngFor="let c of countries" [value]="c.value">{{c.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="description">Place description (max. 200 characters)</label>
                    <textarea class="form-control" id="description" [(ngModel)]="model.place.description"
                        name="description" maxlength="200" rows="3"></textarea>
                </div>
                <div class="form-group check">
                    <label for="alwaysOpen">Always open (24h)</label>
                    <input type="checkbox" class="form-control" id="alwaysOpen" [(ngModel)]="model.alwaysOpen"
                        name="alwaysOpen">
                    <span>
                        Please activate if the defibrillator is accessible at any time
                    </span>
                </div>
                <div class="form-group">
                    <label for="alwaysOpen">Location published</label>
                    <input type="checkbox" class="form-control" id="published" [(ngModel)]="model.published"
                        name="published">
                    <span>
                        Is this defibrillator shown on the map?
                    </span>
                </div>
                <div class="form-group" *ngIf="googlePlace.placeId && googlePlace.openingHours">
                    <p>Google Opening Hours</p>
                    <div *ngFor="let openingHour of googlePlace.openingHours; let i = index;">
                        {{googlePlace.openingHours[i]}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="contactTitle">Contact title</label>
                    <input type="text" class="form-control" id="contactTitle" [(ngModel)]="model.place.contactTitle"
                        name="contactTitle">
                </div>
                <div class="form-group">
                    <label for="contactFirstName">Contact first name</label>
                    <input type="text" class="form-control" id="contactFirstName" [required]="model.place.type==11"
                        [(ngModel)]="model.place.contactFirstName" name="contactFirstName">
                </div>
                <div class="form-group">
                    <label for="contactLastName">Contact last name</label>
                    <input type="text" class="form-control" id="contactLastName" [required]="model.place.type==11"
                        [(ngModel)]="model.place.contactLastName" name="contactLastName">
                </div>
                <div class="form-group">
                    <label for="phone1">Phone 1</label>
                    <input type="text" class="form-control" id="phone1" [(ngModel)]="model.place.phone1" name="phone1">
                    <p *ngIf="googlePlace.placeId && model.place.phone1 != googlePlace.phoneNumber">
                        {{googlePlace.phoneNumber}}
                        <a class="" (click)="model.place.phone1 = googlePlace.phoneNumber" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="phone2">Phone 2</label>
                    <input type="text" class="form-control" id="phone2" [(ngModel)]="model.place.phone2" name="phone2">
                </div>
                <div class="form-group">
                    <label for="mobile">Mobile</label>
                    <input type="text" class="form-control" id="mobile" [(ngModel)]="model.place.mobile" name="mobile">
                </div>
                <div class="form-group">
                    <label for="fax">Fax</label>
                    <input type="text" class="form-control" id="fax" [(ngModel)]="model.place.fax" name="fax">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="model.place.email" name="email">
                </div>
                <div class="form-group">
                    <label for="website">Website</label>
                    <input type="text" class="form-control" id="website" [(ngModel)]="model.place.website"
                        name="website">
                </div>
                <agm-map [latitude]="googlePlace.placeId ? googlePlace.latitude : lat"
                    [longitude]="googlePlace.placeId ? googlePlace.longitude : lng" [zoom]="zoom"
                    [disableDefaultUI]="false">
                    <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
                        [label]="m.label" [iconUrl]="m.icon" [markerDraggable]="m.draggable"
                        (dragEnd)="markerDragEnd(m, $event)">
                    </agm-marker>
                </agm-map>
                <h4>Defibrillator Position Data</h4>
                <div class="form-group">
                    <label for="latitude">Latitude</label>
                    <input *ngIf="markers" type="number" class="form-control" id="latitude"
                        (ngModelChange)="toggleButton()" [(ngModel)]="this.latitude" name="latitude">
                </div>
                <div class="form-group">
                    <label for="longitude">Longitude</label>
                    <input *ngIf="markers" type="number" class="form-control" id="longitude"
                        (ngModelChange)="toggleButton()" [(ngModel)]="this.longitude" name="longitude">
                </div>
                <button type="button" [disabled]="!buttonActive" class="btn btn-primary" (click)="setMarker()">Set
                    Marker</button>
                <button type="button" [disabled]="!resetButtonActive" class="btn btn-primary float-right"
                    (click)="setMarkerToAddress()">Reset Marker To Address</button>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <hr />
                <h4>Client / 3rd party systems</h4>
                <div class="form-group check">
                    <label>Location owner:</label>
                    <br />
                    <span>
                        <select class="form-control" [(ngModel)]="model.syncPartner" id="syncPartner"
                            name="syncPartner">
                            <option *ngFor="let p of myPartners" [value]="p">{{p}}</option>
                        </select>
                    </span>
                    <span *ngIf="model.syncPartnerId">
                        <br />
                        ID: {{ model.syncPartnerId }}
                    </span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <hr />
                <h4>Owner / responsible operator</h4>
                <div class="form-group check" *ngIf="!ownerExists || ownerInputShow">
                    <p *ngIf="!ownerExists">No owner! Please add here.</p>
                    <label for="ownerEmail">Email</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="ownerEmail" style="margin-right: 56px;"
                            [(ngModel)]="this.ownerEmail" name="ownerEmail" (blur)="checkOwnerMail()">
                        <span class="input-group-btn">
                            <button class="btn btn-primary" type="button" style="margin-left: -56px;"
                                (click)="checkOwnerMail()">Check</button>
                        </span>
                    </div>
                </div>
                <ng-container *ngIf="ownerExists">
                    <div class="form-group">
                        <button class="btn btn-warning" *ngIf="ownerEditable" type="button" (click)="editOwner()">Edit
                            User</button>
                        <button class="btn btn-danger" type="button" (click)="clearOwner()">Remove Owner</button>
                    </div>
                    <div class="form-group">
                        <label>Email: </label> <span>{{model.owner.email}}</span>
                    </div>

                    <div class="form-group">
                        <label>Firstname: </label> <span>{{model.owner.firstName}}</span>
                    </div>

                    <div class="form-group">
                        <label>Lastname: </label> <span>{{model.owner.lastName}}</span>
                    </div>

                    <div class="form-group">
                        <label>Phone: </label> <span>{{model.owner.phoneNumber}}</span>
                    </div>
                </ng-container>
            </div>

            <div class="col-md-6">
                <hr />
                <h4>Deputy of responsible operator</h4>
                <div class="form-group check" *ngIf="!ownerDeputyExists || ownerDeputyInputShow">
                    <p *ngIf="!ownerDeputyExists">No deputy owner! Please add here.</p>
                    <label for="ownerDeputyEmail">Email</label>
                    <div class="input-group">
                        <input type="text" class="form-control" id="ownerDeputyEmail" style="margin-right: 56px;"
                            [(ngModel)]="ownerDeputyEmail" name="ownerDeputyEmail" (blur)="checkOwnerDeputyMail()">
                        <span class="input-group-btn">
                            <button class="btn btn-primary" type="button" style="margin-left: -56px;"
                                (click)="checkOwnerDeputyMail()">Check</button>
                        </span>
                    </div>
                </div>
                <ng-container *ngIf="ownerDeputyExists">
                    <div class="form-group">
                        <button class="btn btn-warning" *ngIf="ownerDeputyEditable" type="button"
                            (click)="editOwnerDeputy()">Edit User</button>
                        <button class="btn btn-danger" type="button" (click)="clearOwnerDeputy()">Remove Deputy
                            Owner</button>
                    </div>
                    <div class="form-group">
                        <label>Email: </label> <span>{{model.ownerDeputy.email}}</span>
                    </div>

                    <div class="form-group">
                        <label>Firstname: </label> <span>{{model.ownerDeputy.firstName}}</span>
                    </div>

                    <div class="form-group">
                        <label>Lastname: </label> <span>{{model.ownerDeputy.lastName}}</span>
                    </div>

                    <div class="form-group">
                        <label>Phone: </label> <span>{{model.ownerDeputy.phoneNumber}}</span>
                    </div>
                </ng-container>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <hr />
                <h4>Defibrillator details</h4>
                <div class="form-group">
                    <label for="manufacturer">Manufacturer</label>
                    <input type="text" class="form-control" id="manufacturer" [(ngModel)]="model.manufacturer"
                        name="manufacturer">
                </div>
                <div class="form-group">
                    <label for="modelName">Type/Model name</label>
                    <input type="text" class="form-control" id="modelName" [(ngModel)]="model.modelName"
                        name="modelName">
                </div>
                <div class="form-group">
                    <label for="serialNumber">Serial number</label>
                    <input type="text" class="form-control" id="serialNumber" [(ngModel)]="model.serialNumber"
                        name="serialNumber">
                </div>
                <div class="form-group">
                    <label for="warrantyInYears">Warranty of manufacturer (in years)</label>
                    <input type="text" class="form-control" id="warrantyInYears" [(ngModel)]="model.warrantyInYears"
                        name="warrantyInYears">
                </div>
                <div class="form-group">
                    <label for="purchasedOn">Date of purchase</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.purchasedOn" name="purchasedOn" mode="day" theme="dp-material"
                        [config]="dpConfig"></dp-date-picker>
                </div>
                <div class="form-group">
                    <label for="fax">Date of initial operation</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.initialOperationOn" name="initialOperationOn" mode="day"
                        theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
            </div>
            <div class="col-md-6">
                <hr />
                <h4>Security regulations</h4>
                <div class="form-group">
                    <label for="isUnderRegulation">Regulation (BetreibV)</label>
                    <span>
                        <input type="checkbox" class="form-control move-left" id="isUnderRegulation"
                            [(ngModel)]="model.isUnderRegulation" name="isUnderRegulation">
                        Is this defibrillator under regulation by law?
                    </span>
                </div>
                <div class="form-group">
                    <label for="nextInspection">Next inspection</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.nextInspection" name="nextInspection" mode="day"
                        theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <hr />
                <h4>Accessories / PAD 1</h4>
                <div class="form-group">
                    <label for="pad1Expiration">Pad expiration</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.pad1Expiration" name="pad1Expiration" mode="day"
                        theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
                <div class="form-group">
                    <label for="pad1ExpirationReminder">Reminder (email)</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.pad1ExpirationReminder" name="pad1ExpirationReminder" mode="day"
                        theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
                <div class="form-group">
                    <label for="pad1SerialNumber">Serial number</label>
                    <input type="text" class="form-control" id="pad1SerialNumber" [(ngModel)]="model.pad1SerialNumber"
                        name="pad1SerialNumber">
                </div>
                <div class="form-group">
                    <label for="pad1LotNumber">LOT number</label>
                    <input type="text" class="form-control" id="pad1LotNumber" [(ngModel)]="model.pad1LotNumber"
                        name="pad1LotNumber">
                </div>
            </div>
            <div class="col-md-6">
                <hr />
                <h4>Accessories / PAD 2</h4>
                <div class="form-group">
                    <label for="pad2Expiration">Pad expiration</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.pad2Expiration" name="pad2Expiration" mode="day"
                        theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
                <div class="form-group">
                    <label for="pad2ExpirationReminder">Reminder (email)</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.pad2ExpirationReminder" name="pad2ExpirationReminder" mode="day"
                        theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
                <div class="form-group">
                    <label for="pad2SerialNumber">Serial number</label>
                    <input type="text" class="form-control" id="pad2SerialNumber" [(ngModel)]="model.pad2SerialNumber"
                        name="pad2SerialNumber">
                </div>
                <div class="form-group">
                    <label for="pad2LotNumber">LOT number</label>
                    <input type="text" class="form-control" id="pad2LotNumber" [(ngModel)]="model.pad2LotNumber"
                        name="pad2LotNumber">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6">
                <hr />
                <h4>Accessories / Battery</h4>
                <div class="form-group">
                    <label for="batteryExpiration">Battery expiration</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.batteryExpiration" name="batteryExpiration" mode="day"
                        theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
                <div class="form-group">
                    <label for="batteryExpirationReminder">Reminder (email)</label>
                    <br />
                    <dp-date-picker [(ngModel)]="model.batteryExpirationReminder" name="batteryExpirationReminder"
                        mode="day" theme="dp-material" [config]="dpConfig"></dp-date-picker>
                </div>
                <div class="form-group">
                    <label for="batterySerialNumber">Serial number</label>
                    <input type="text" class="form-control" id="batterySerialNumber"
                        [(ngModel)]="model.batterySerialNumber" name="batterySerialNumber">
                </div>
                <div class="form-group">
                    <label for="batteryLotNumber">LOT number</label>
                    <input type="text" class="form-control" id="batteryLotNumber" [(ngModel)]="model.batteryLotNumber"
                        name="batteryLotNumber">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12">
                <h4>Images (only the first 4 images are shown on map)</h4>
                <div *ngFor="let image of model.mediaFiles; let i = index;">
                    <div class="col-md-2">
                        <img [src]="apiUrl + '/api/defibrillator/image/' + image.id + '/300'" />
                        <a (click)="removeImage(i)">
                            <fa-icon [icon]="faMinusCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </div>
                </div>
                <div class="fileUpload btn btn-primary">
                    <span>
                        <fa-icon [icon]="faPlusCircle"></fa-icon> Add Image
                    </span>
                    <input class="upload" #fileInput type="file" (change)="addImage($event)" placeholder="Upload image"
                        accept=".png,.jpeg,.jpg" style="visibility: collapse; display: none;">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right" style="margin-top: 20px;">
                <button type="button" class="btn btn-secondary " [routerLink]="['/defibrillator']">Cancel</button>
                <button type="submit" class="btn btn-success"
                    [disabled]="!defiForm.form.valid || this.submitted">Save</button>
            </div>
        </div>
    </form>
</div>