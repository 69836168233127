import { Injectable, Inject } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { Store } from '@ngrx/store';
import { Actions, Effect, ROOT_EFFECTS_INIT, ofType } from '@ngrx/effects';
import { Observable, of, from } from 'rxjs';
import { switchMap, withLatestFrom, map, mergeMap } from 'rxjs/operators';
import { Go } from '../app.router';

import {
  ActionTypes,
  ChangeLanguage,
  ToggleRegisterAction,
  ShowModalAction,
} from '../app.actions';
import { AppState } from '../app.state';
import { getUser } from '../app.reducer';
import { getParams, globals } from '../../utils';
import { ROUTER_NAVIGATION } from '@ngrx/router-store';
import { CookieService } from '../../services/cookie.storage';

@Injectable()
export class AppEffects {
  @Effect()
  changeLanguage$: Observable<any> = this.actions$.pipe(
    ofType(ActionTypes.CHANGE_LANGUAGE),
    switchMap((action: ChangeLanguage) => {
      if (action.store) {
        this.cookieStorage.set('lang', action.payload);
      }
      // change url if needed;
      if (globals.isBrowser && !action.skipNavigation) {
        const url = globals.window.location.pathname;
        const search = globals.window.location.search;
        if (url.indexOf(`/${action.payload}`) !== 0) {
          const match = url.match(/^[\/]{0,1}(en|de)(.*)/);
          let segments = match ? match[2] : url;
          if (segments.endsWith('/')) {
            segments = segments.substr(0, segments.length - 1);
          }
          this.router.navigateByUrl(
            `/${action.payload}${segments ? `${segments}` : ''}${search}`
          );
        }
      }
      globals.translate.use(action.payload);
      return of({
        type: ActionTypes.CHANGED_LANGUAGE,
        payload: action.payload,
      });
    })
  );

  @Effect()
  toggleModal$: Observable<any> = this.actions$.pipe(
    ofType(ActionTypes.TOGGLE_MODAL),
    // .switchMap((action: ToggleAction) => {
    //     return Observable.of({
    //         type: ActionTypes.TOGGLE_MODAL,
    //         payload: null
    //     });
    // })
    withLatestFrom(this.store$.select('auth')),
    map(([action, auth]: [any, any]) => {
      let qp;
      if (!globals.isBrowser) {
        return { type: ActionTypes.NULL_ACTION };
      }
      /* const hash = this.window.location.hash;
        const extras: NavigationExtras = { replaceUrl: true, fragment: (hash && hash.slice(1)) || undefined };
        if (action.payload || auth.showLogin || (auth.showRegister && auth.showRegister.step > 0)) {
          qp = getParams(this.window.location.pathname);
          qp.modal = action.component;
          extras.replaceUrl = false;
        }
        return new Go({ path: [location.pathname], query: qp, extras: extras }); */
    })
  );

  @Effect()
  showModal$: Observable<any> = this.actions$
    .pipe(ofType(ActionTypes.SHOW_MODAL))
    .pipe(
      switchMap((action: ShowModalAction) => {
        return of({
          type: ActionTypes.TOGGLE_MODAL,
          payload: action.payload,
        });
      })
    );

  @Effect()
  toggleLogin$: Observable<any> = this.actions$
    .pipe(ofType(ActionTypes.TOGGLE_LOGIN))
    .pipe(
      switchMap(() => {
        return of({
          type: ActionTypes.TOGGLE_MODAL,
          payload: '',
        });
      })
    );

  @Effect()
  toggleRegister$: Observable<any> = this.actions$
    .pipe(ofType(ActionTypes.TOGGLE_REGISTER))
    .pipe(
      switchMap((action: ToggleRegisterAction) => {
        return of({
          type: ActionTypes.TOGGLE_MODAL,
          payload: action.payload && action.payload.step ? true : false,
        });
      })
    );

  constructor(
    private actions$: Actions,
    private store$: Store<AppState>,
    private router: Router,
    private cookieStorage: CookieService
  ) {}
}
