<div class='container'>
    <div class="row">
        <div class="col-8">
            <h1>First Responder</h1>
        </div>
        <div class="col-4 text-right">
            <a [routerLink]="['/firstresponderadmin/new']" class="btn btn-success pull-right">+ New Responder</a>
        </div>
    </div>
    <div class="row">
        <div class="col-12">

            <ngx-datatable class="material striped" [rows]="data" [columns]="columns" [columnMode]="'force'"
                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [loadingIndicator]="loading">
            </ngx-datatable>

            <ng-template #editItemTmpl let-row="row" let-value="value">
                <a [routerLink]="['/firstresponderadmin/' + row.id]">
                    <fa-icon [icon]="faEdit"></fa-icon>
                </a>
            </ng-template>

        </div>
    </div>
</div>