import { Action } from '@ngrx/store';
import { type } from '../utils/type';
import { Observable } from 'rxjs';
import { Operator } from 'rxjs';

export const ActionTypes = {
  LOGIN: type('[Auth] Login'),
  LOGOUT: type('[Auth] Logout'),
  LOGIN_SUCCESS: type('[Auth] Login Success'),
  LOGIN_FAILED: type('[Auth] Login Failed'),
  UPDATE_USER_IMAGE: type('[Auth] Update User Image'),
  USER_SET: type('[Auth] User Set'),

  REGISTER: type('[Auth] Register'),
  REGISTER_SUCCESS: type('[Auth] Register Success'),
  REGISTER_FAILED: type('[Auth] Register Failed'),

  TOGGLE_LOGIN: type('[Auth] Toggle Login'),
  TOGGLE_REGISTER: type('[Auth] Toggle Register'),

  RESTORE_PASSWORD: type('[Auth] Restore Password'),
  RESTORE_PASSWORD_SUCCESS: type('[Auth] Restore Password Success'),
  RESTORE_PASSWORD_FAILED: type('[Auth] Restore Password Failed'),

  RESET_PASSWORD: type('[Auth] Reset Password'),
  RESET_PASSWORD_SUCCESS: type('[Auth] Reset Password Success'),
  RESET_PASSWORD_FAILED: type('[Auth] Reset Password Failed'),

  LOAD_USER: type('[Api] Load User'),
  LOAD_USER_SUCCESS: type('[Api] Load User Success'),
  LOAD_USER_FAILED: type('[Api] Load User Fail'),

  CHANGE_LANGUAGE: type('[App] Change Language'),
  CHANGED_LANGUAGE: type('[App] Changed Language'),

  TOGGLE_EDIT: type('[App] Toggle Edit'),
  TOGGLE_MODAL: type('[App] Toggle Modal'),

  SHOW_MODAL: type('[App] Show Modal'),

  SET_ROUTER_STATE: type('[App] Set Router State'),

  // END PROFILE ACTIONS

  NULL_ACTION: type('[App] Null action'),
};

export interface PayloadAction {
  type: string;
  payload: any;
}

export declare class PayloadActions<V = PayloadAction> extends Observable<V> {
  constructor(source?: Observable<V>);
  lift<R>(operator: Operator<V, R>): Observable<R>;
  ofType<V2 extends V = V>(...allowedTypes: string[]): PayloadActions<V2>;
}

export class LoginAction implements PayloadAction {
  type = ActionTypes.LOGIN;
  constructor(public payload: { username; password; onSuccess? }) {}
}

export class RestorePasswordAction implements PayloadAction {
  type = ActionTypes.RESTORE_PASSWORD;
  constructor(public payload: string) {}
}

export class ResetPasswordAction implements PayloadAction {
  type = ActionTypes.RESET_PASSWORD;
  constructor(public payload: { email; password; code }) {}
}

export class LogoutAction implements Action {
  type = ActionTypes.LOGOUT;
}

export class ToggleLoginAction implements Action {
  type = ActionTypes.TOGGLE_LOGIN;
}

export class ToggleRegisterAction implements PayloadAction {
  type = ActionTypes.TOGGLE_REGISTER;
  constructor(public payload: { email?; password?; step? } = { step: 0 }) {}
}

export class RegisterAction implements PayloadAction {
  type = ActionTypes.REGISTER;
  constructor(public payload: { email; password; firstname; lastname }) {}
}

export class ChangeLanguage implements PayloadAction {
  type = ActionTypes.CHANGE_LANGUAGE;
  constructor(
    public payload: string,
    public store = false,
    public skipNavigation = false
  ) {}
}

export class ShowModalAction implements PayloadAction {
  type = ActionTypes.SHOW_MODAL;
  constructor(public payload: string) {}
}

export class SetRouterState implements PayloadAction {
  type = ActionTypes.SET_ROUTER_STATE;
  constructor(public payload: string[]) {}
}

export class LoadUserAction implements PayloadAction {
  type = ActionTypes.LOAD_USER;
  constructor(public payload: string) {}
}

export class LoadUserSuccessAction implements PayloadAction {
  type = ActionTypes.LOAD_USER_SUCCESS;
  constructor(public payload: string) {}
}

export class SetUser implements PayloadAction {
  type = ActionTypes.USER_SET;
  constructor(public payload: any) {}
}
