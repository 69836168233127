import { Injectable, Inject } from '@angular/core';
import { ApiService } from '../../services/api';
import { AuthService } from '../../services/auth';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Go } from '../app.router';
import { Observable, of, from } from 'rxjs';
import {
  mergeMap,
  switchMap,
  map,
  withLatestFrom,
  catchError,
} from 'rxjs/operators';

import { ActionTypes, LoginAction } from '../app.actions';
import { AppState } from '../app.state';
import { getLanguage } from '../app.reducer';
import {
  RestorePasswordAction,
  ResetPasswordAction,
  RegisterAction,
} from '../../state';

@Injectable()
export class AuthEffects {
  @Effect()
  login$: Observable<Action> = this.actions$
    .pipe(ofType(ActionTypes.LOGIN))
    .pipe(
      map((action: LoginAction) => action.payload),
      switchMap((payload) => {
        return this.auth
          .login(payload.username, payload.password)
          .pipe(
            map((res) => {
              return {
                type: ActionTypes.LOGIN_SUCCESS,
                payload: res,
                onSuccess: payload.onSuccess,
              };
            })
          )
          .pipe(
            catchError((err) => {
              return of({ type: ActionTypes.LOGIN_FAILED, payload: err });
            })
          );
      })
    );

  @Effect()
  restorePass$: Observable<Action> = this.actions$
    .pipe(ofType(ActionTypes.RESTORE_PASSWORD))
    .pipe(
      map((action: RestorePasswordAction) => action.payload),
      withLatestFrom(this.store$.select(getLanguage)),
      switchMap(([payload, lang]) => {
        return this.auth
          .recover(payload, lang)
          .pipe(
            map((res) => {
              return {
                type: ActionTypes.RESTORE_PASSWORD_SUCCESS,
                payload: res,
              };
            })
          )
          .pipe(
            catchError((err) => {
              return of({
                type: ActionTypes.RESTORE_PASSWORD_FAILED,
                payload: err,
              });
            })
          );
      })
    );

  @Effect()
  resetPass$: Observable<Action> = this.actions$
    .pipe(ofType(ActionTypes.RESET_PASSWORD))
    .pipe(
      map((action: ResetPasswordAction) => action.payload),
      switchMap((payload) => {
        return this.auth
          .reset(payload.email, payload.password, payload.code)
          .pipe(
            map((res) => {
              return { type: ActionTypes.RESET_PASSWORD_SUCCESS, payload: res };
            })
          )
          .pipe(
            catchError((err) => {
              return of({
                type: ActionTypes.RESET_PASSWORD_FAILED,
                payload: err,
              });
            })
          );
      })
    );

  @Effect()
  userSet$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LOGIN_SUCCESS),
    withLatestFrom(this.store$.select('routerReducer')),
    mergeMap(([action, router]: [any, any]) => {
      const actions = [];
      if (action.onSuccess === 'redirect') {
        actions.push(new Go({ path: ['/konto'] }));
      } else if (router.state.url.indexOf('/logout') === 0) {
        // go to home after logout;
        actions.push(new Go({ path: [`/`] }));
        // or go to profile;
        // actions.push(go([`/profile/${action.payload.userId}/view`]));
      } else {
        // actions.push(new ToggleLoginAction());
      }
      return from(actions);
    })
  );

  @Effect()
  logout$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LOGOUT),
    map(() => {
      this.auth.logout();
      // const history = this.window.history;
      if (history) {
        history.pushState({ data: 'logout' }, document.title, '/');
        history.pushState({ data: 'logout' }, document.title, '/');
      }
      return new Go({ path: ['/'] });
    })
  );

  @Effect()
  register$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.REGISTER),
    map((action: RegisterAction) => action.payload),
    withLatestFrom(this.store$.select(getLanguage)),
    switchMap(([payload, lang]) => {
      return this.auth
        .register(
          payload.email,
          payload.password,
          payload.firstname,
          payload.lastname,
          lang
        )
        .pipe(
          map((res) => {
            return {
              type: ActionTypes.REGISTER_SUCCESS,
              payload: {
                response: res,
                email: payload.email,
                password: payload.password,
              },
            };
          })
        )
        .pipe(
          catchError((err) => {
            return of({ type: ActionTypes.REGISTER_FAILED, payload: err });
          })
        );
    })
  );

  @Effect()
  onRegister$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.REGISTER_SUCCESS),
    map((action: RegisterAction) => action.payload),
    map((payload) => {
      return new LoginAction({
        username: payload.email,
        password: payload.password,
        onSuccess: 'redirect',
      });
    })
  );

  constructor(
    private actions$: Actions,
    private api: ApiService,
    private auth: AuthService,
    private store$: Store<AppState>
  ) {}
}
