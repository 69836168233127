import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/index';
import { RegistrationRequest } from '../../models/registration-request.model';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-defi-registration-request-list',
  styleUrls: ['../defis/defi-list.component.scss'],
  templateUrl: './registration-request-list.component.html',
})
export class RegistrationRequestListComponent implements OnInit {
  // font awesome
  public faEdit = faEdit;

  // loading indicator
  loading = false;

  @ViewChild('submittedItemTmpl', { static: true })
  submittedItemTmpl: TemplateRef<any>;
  @ViewChild('editItemTmpl', { static: true }) editItemTmpl: TemplateRef<any>;

  // column definition
  columns;

  data: Array<RegistrationRequest>;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.columns = [
      { prop: 'requestId', name: 'Id', width: 10 },
      {
        prop: 'submitted',
        name: 'Submitted',
        cellTemplate: this.submittedItemTmpl,
        width: 90,
      },
      { prop: 'placeTypeName', name: 'Category', width: 100 },
      { prop: 'placeName', name: 'Name', width: 180 },
      { prop: 'contactTitle', name: 'Title', width: 50 },
      { prop: 'contactFirstName', name: 'First name', width: 140 },
      { prop: 'contactLastName', name: 'Last name', width: 140 },
      { name: 'Actions', cellTemplate: this.editItemTmpl, width: 20 },
    ];

    // indicate loading
    this.loading = true;

    // load data
    this.loadData();
  }

  private loadData() {
    // load data (take, skip)
    this.api.getDefiRegistrationRequests().subscribe((response) => {
      // store the data
      this.data = new Array<RegistrationRequest>();
      for (const entry of response) {
        this.data.push(new RegistrationRequest().deserialize(entry));
      }

      // loading finished
      this.loading = false;
    });
  }
}
