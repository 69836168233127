<div class='container'>
    <div class="row">
        <div class="col-8">
            <h1>User Admin</h1>
        </div>
        <div class="col-4 text-right">
            <a [routerLink]="['/useradmin/new']" class="btn btn-success">+ New Admin</a>
        </div>
    </div>
    <div class="row">
        <div class="col-12">

            <ngx-datatable class="material striped" [rows]="data" [columns]="columns" [columnMode]="'force'"
                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [loadingIndicator]="loading">
            </ngx-datatable>

            <ng-template #editItemTmpl let-row="row" let-value="value">
                <a [routerLink]="['/useradmin/' + row.id]">
                    <fa-icon [icon]="faEdit"></fa-icon>
                </a>
            </ng-template>

            <ng-template #countryTmpl let-row="row" let-value="value">
                <span *ngFor="let country of row.adminCountriesDisplayName; let i = index;">
                    {{country}}<br />
                </span>
            </ng-template>

            <ng-template #partnerTmpl let-row="row" let-value="value">
                <span *ngFor="let partner of row.adminPartners; let i = index;">
                    {{partner}}<br />
                </span>
            </ng-template>

            <ng-template #rolesTmpl let-row="row" let-value="value">
                <span *ngFor="let role of row.roles; let i = index;">
                    {{role}}<br />
                </span>
            </ng-template>

        </div>
    </div>
</div>