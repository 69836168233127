import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './core/login/login.component';
import { ForgotPasswordComponent } from './core/login/forgot-password.component';
import { PasswordResetComponent } from './core/login/password-reset.component';
import { HomeComponent } from './home/home.component';
import { DefiListComponent } from './defis/defis/defi-list.component';
import { DefiFormComponent } from './defis/defis/defi-form.component';
import { ChangeRequestListComponent } from './defis/change-requests/change-request-list.component';
import { ChangeRequestFormComponent } from './defis/change-requests/change-request-form.component';
import { DeleteRequestListComponent } from './defis/delete-requests/delete-request-list.component';
import { DeleteRequestFormComponent } from './defis/delete-requests/delete-request-form.component';
import { RegistrationRequestListComponent } from './defis/registration-requests/registration-request-list.component';
import { RegistrationRequestFormComponent } from './defis/registration-requests/registration-request-form.component';
import { ImageRequestListComponent } from './defis/image-requests/image-request-list.component';
import { ImageRequestFormComponent } from './defis/image-requests/image-request-form.component';
import { UserListComponent } from './defis/user-admin/user-list.component';
import { UserFormComponent } from './defis/user-admin/user-form.component';
import { FirstresponderListComponent } from './defis/firstresponder-admin/firstresponder-list.component';
import { FirstresponderFormComponent } from './defis/firstresponder-admin/firstresponder-form.component';
import { FirstresponderRequestListComponent } from './defis/firstresponder-requests/firstresponder-request-list.component';
import { FirstresponderRequestFormComponent } from './defis/firstresponder-requests/firstresponder-request-form.component';
import { AuthGuard } from './services/authGuard';

export const AppRoutes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'password-reset', component: PasswordResetComponent },
  { path: 'home', component: HomeComponent, canActivate: [AuthGuard] },
  {
    path: 'defibrillator',
    component: DefiListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'defibrillator/:id',
    component: DefiFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change',
    component: ChangeRequestListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'change/:id',
    component: ChangeRequestFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'deletion',
    component: DeleteRequestListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'deletion/:id',
    component: DeleteRequestFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registration',
    component: RegistrationRequestListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'registration/:id',
    component: RegistrationRequestFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'image',
    component: ImageRequestListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'image/:id',
    component: ImageRequestFormComponent,
    canActivate: [AuthGuard],
  },
  { path: 'useradmin', component: UserListComponent, canActivate: [AuthGuard] },
  {
    path: 'useradmin/:id',
    component: UserFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'firstresponderadmin',
    component: FirstresponderListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'firstresponderadmin/:id',
    component: FirstresponderFormComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'firstresponderrequest',
    component: FirstresponderRequestListComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'firstresponderrequest/:id',
    component: FirstresponderRequestFormComponent,
    canActivate: [AuthGuard],
  },
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: '**', redirectTo: 'home' },
];
