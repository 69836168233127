import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { AuthService } from '../../services/index';

@Component({
  templateUrl: 'password-reset.component.html',
})
export class PasswordResetComponent implements OnInit {
  model: any = {};
  loading = false;
  requestState: number;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // start with status 1
    this.requestState = 1; // start

    this.activatedRoute.queryParams.subscribe((params: Params) => {
      this.model.username = params.email;
      this.model.code = params.code;
    });
  }

  resetPassword() {
    this.loading = true;
    this.authService
      .reset(this.model.username, this.model.code, this.model.password)
      .subscribe(
        (data) => {
          if (data === true) {
            this.requestState = 2;
          } // OK
          else {
            this.requestState = 3; // error
            this.loading = false;
          }
        },
        (error) => {
          this.requestState = 3; // error
          this.loading = false;
        }
      );
  }
}
