import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ApiService } from '../../services/index';

import { Defi, Countries } from '../../models/defi.model';
import { ChangeRequest } from '../../models/change-request.model';
import {
  PlaceCategory,
  PlaceCategoryList,
} from '../../models/place-category.model';
import { switchMap } from 'rxjs/operators';
import {
  faPlusCircle,
  faCheckCircle,
  faMinusCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-defi-change-request-form',
  styleUrls: ['./change-request-form.component.scss'],
  templateUrl: './change-request-form.component.html',
})
export class ChangeRequestFormComponent {
  // fontawesome
  faPlusCircle = faPlusCircle;
  faCheckCircle = faCheckCircle;
  faMinusCircle = faMinusCircle;
  faExclamationTriangle = faExclamationTriangle;

  model: Defi = new Defi();
  newDefi: Defi = new Defi();
  request: ChangeRequest = new ChangeRequest();

  submitted = false;
  lat: number;
  lng: number;
  markers;
  longitude;
  latitude;

  marker = [];

  zoom = 18;
  countries = Countries;
  selectedValue = null;

  categories: Array<PlaceCategory> = PlaceCategoryList.getCategoryList();
  myPartners;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => this.api.getDefiChangeRequest(params.id))
      )
      .subscribe((response) => {
        // existing defi from service
        this.request = new ChangeRequest().deserialize(response);
        this.newDefi = this.request.newDefi;
        this.model = this.request.defi;
        this.loadPartners();
        this.lat = this.model.place.address.location.latitude;
        this.lng = this.model.place.address.location.longitude;
        this.latitude = this.model.location.latitude;
        this.longitude = this.model.location.longitude;
        this.loadMarkers();
      });
  }

  loadPartners() {
    this.api.getMyPartners().subscribe((response) => {
      this.myPartners = response;
      if (!this.model.syncPartner && this.myPartners) {
        this.model.syncPartner = this.myPartners[0];
      }

      // if no partners besides cisali are available, shrink the country list
      if (this.myPartners.length == 1 && this.myPartners[0] === 'Cisali') {
        this.api.getMyCountries().subscribe((response) => {
          this.countries = Countries.filter((item) => {
            if (response.includes(item.value)) {
              return true;
            }
            return false;
          });
          if (this.countries.length == 1) {
            this.model.place.address.country = this.countries[0].value;
          }
        });
      } else {
        this.countries = Countries;
      }
    });
  }

  loadMarkers() {
    this.markers = this.marker = [
      {
        lat: this.lat,
        lng: this.lng,
        icon: '/assets/home.png',
        draggable: false,
      },
      {
        lat: this.latitude ? this.latitude : this.lat,
        lng: this.longitude ? this.longitude : this.lng,
        icon: '/assets/defi.png',
        draggable: true,
      },
    ];
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    this.model.location.latitude = this.latitude;
    this.model.location.longitude = this.longitude;

    // confirm delete request
    this.api
      .postDefiChangeRequest(this.request, this.model)
      .subscribe((response) => {
        this.router.navigate(['change']);
      });
  }

  ignoreRequest() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // delete the request
    this.api.deleteDefiChangeRequest(this.request).subscribe((response) => {
      this.router.navigate(['change']);
    });
  }
}
