export class PlaceCategory {
    constructor(id: number, name: string) {
        this.id = id;
        this.name = name;
    }

    id: number;
    name: string;
}

export class PlaceCategoryList
{
    private static _instance: PlaceCategoryList = new PlaceCategoryList();

    constructor() {
        if (PlaceCategoryList._instance) {
            throw new Error("The Logger is a singleton class and cannot be created!");
        }

        PlaceCategoryList._instance = this;
    }

    private static categories: Array<PlaceCategory>;

    public static getCategoryList()
    {
        if (!this.categories) {
            // build category list
            this.categories = Array<PlaceCategory>();
            this.categories.push(new PlaceCategory(0, 'Pharmacies / Medical practices'));
            this.categories.push(new PlaceCategory(1, 'Banks / Insurances'));
            this.categories.push(new PlaceCategory(2, 'Companies / Enterprises'));
            this.categories.push(new PlaceCategory(3, 'Education / Learning / Culture'));
            this.categories.push(new PlaceCategory(4, 'Leisure / Sports'));
            this.categories.push(new PlaceCategory(5, 'Restaurants / Hotels'));
            this.categories.push(new PlaceCategory(6, 'Federal and regional government / Communities / Municipalities'));
            this.categories.push(new PlaceCategory(7, 'Non-profit organisations / Charities / Foundations'));
            this.categories.push(new PlaceCategory(8, 'Cooperatives / Associations'));
            this.categories.push(new PlaceCategory(9, 'Justice / Police / Security'));
            this.categories.push(new PlaceCategory(10, 'Hospitals / Care centers / Rehab'));
            this.categories.push(new PlaceCategory(11, 'Individuals'));
            this.categories.push(new PlaceCategory(12, 'Transportation / Traffic'));
            this.categories.push(new PlaceCategory(13, 'Other'));
        }

        // return list
        return this.categories;
    }
}