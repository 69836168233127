import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../../services/index';

@Component({
  selector: 'app-header-layout',
  styleUrls: ['./header-layout.component.scss'],
  templateUrl: './header-layout.component.html',
})
export class HeaderLayoutComponent {
  constructor(public authService: AuthService, private router: Router) {}

  logout() {
    this.authService.logout();
  }
}
