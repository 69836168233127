import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DpDatePickerModule } from 'ng2-date-picker';
import { AgmCoreModule } from '@agm/core';

import { SafePipe } from './safe.pipe';
import { DefiListComponent } from './defis/defi-list.component';
import {
  DefiFormComponent,
  NgbdModalContent,
} from './defis/defi-form.component';
import { NgModalConfirm } from '../shared/modal';
import { ChangeRequestListComponent } from './change-requests/change-request-list.component';
import { ChangeRequestFormComponent } from './change-requests/change-request-form.component';
import { DeleteRequestListComponent } from './delete-requests/delete-request-list.component';
import { DeleteRequestFormComponent } from './delete-requests/delete-request-form.component';
import { RegistrationRequestListComponent } from './registration-requests/registration-request-list.component';
import { RegistrationRequestFormComponent } from './registration-requests/registration-request-form.component';
import { ImageRequestListComponent } from './image-requests/image-request-list.component';
import { ImageRequestFormComponent } from './image-requests/image-request-form.component';
import { UserListComponent } from './user-admin/user-list.component';
import { UserFormComponent } from './user-admin/user-form.component';
import { FirstresponderListComponent } from './firstresponder-admin/firstresponder-list.component';
import { FirstresponderFormComponent } from './firstresponder-admin/firstresponder-form.component';
import { FirstresponderRequestListComponent } from './firstresponder-requests/firstresponder-request-list.component';
import { FirstresponderRequestFormComponent } from './firstresponder-requests/firstresponder-request-form.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgxDatatableModule,
    FormsModule,
    FontAwesomeModule,
    DpDatePickerModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD-UjYxzjZuvVkL2KdIPqg6Sv2DyIr-I4Y',
    }),
  ],
  declarations: [
    DefiListComponent,
    DefiFormComponent,
    ChangeRequestListComponent,
    ChangeRequestFormComponent,
    DeleteRequestListComponent,
    DeleteRequestFormComponent,
    RegistrationRequestListComponent,
    RegistrationRequestFormComponent,
    ImageRequestListComponent,
    ImageRequestFormComponent,
    UserListComponent,
    FirstresponderListComponent,
    FirstresponderRequestListComponent,
    SafePipe,
    NgbdModalContent,
    NgModalConfirm,
    UserFormComponent,
    FirstresponderFormComponent,
    FirstresponderRequestFormComponent,
  ],
  exports: [DefiListComponent, DefiFormComponent],
  entryComponents: [NgbdModalContent, NgModalConfirm],
})
export class DefiModule {}
