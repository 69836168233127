import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { CoreModule } from './core/core.module';
import { DefiModule } from './defis/defi.module';
import {
  ApiService,
  AuthService,
  GlobalEventsManager,
  InterceptedHttp,
} from './services';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AppRoutes } from './app.routing';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { StoreModule } from '@ngrx/store';
import {
  getInitialState,
  reducers,
  metaReducers,
  AuthEffects,
  ApiEffects,
  AppEffects,
  RouterEffects,
} from './state';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { CookieService } from './services/cookie.storage';
import { AuthGuard } from './services/authGuard';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';

@NgModule({
  declarations: [AppComponent, HomeComponent],
  imports: [
    BrowserModule,
    FormsModule,
    NgbModule,
    RouterModule.forRoot(AppRoutes),
    CoreModule,
    DefiModule,
    HttpClientModule,
    StoreRouterConnectingModule.forRoot(),
    StoreModule.forRoot(reducers, {
      metaReducers,
      initialState: getInitialState,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    EffectsModule.forRoot([AuthEffects, ApiEffects, AppEffects, RouterEffects]),
    FontAwesomeModule,
    NgxDatatableModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptedHttp,
      multi: true,
    },
    ApiService,
    AuthService,
    AuthGuard,
    CookieService,
    GlobalEventsManager,
    { provide: 'ORIGIN_URL', useValue: location.origin },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
