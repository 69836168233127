<nav class="navbar navbar-default">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <ul class="nav nav-pills" *ngIf="showNavBar">
                    <!--<li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/home']"><i class="fa fa-home" aria-hidden="true"></i></a>
                    </li>-->
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/defibrillator']">Defibrillators</a>
                    </li>
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/registration']">New Requests</a>
                    </li>
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/change']">Change Requests</a>
                    </li>
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/image']">Image Requests</a>
                    </li>
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/deletion']">Deletion Requests</a>
                    </li>
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/firstresponderrequest']">First Responder Requests</a>
                    </li>
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/useradmin']" *ngIf="authService.isSuperAdmin">User Admin</a>
                    </li>
                    <li [routerLinkActive]="['active']" role="presentation">
                        <a [routerLink]="['/firstresponderadmin']" *ngIf="authService.isSuperAdmin">First Responder</a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</nav>