import { Injectable } from '@angular/core';

@Injectable()
export class Shared {
    public static apiUrl: string = "http://cisali-service.kantan.eu";
    //public static apiUrl: string = "https://api.cisali.com";
    //public static apiUrl: string = "http://localhost:2330";

    constructor() {
    }
}