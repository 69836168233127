import { createSelector } from 'reselect';
import { MetaReducer, ActionReducerMap } from '@ngrx/store';
import * as fromRouter from '@ngrx/router-store';

import { AppState, AuthState } from './app.state';
import { ActionTypes } from './app.actions';
import { PayloadAction } from './app.actions';

import { environment } from '../../environments/environment';
import { asyncReducer } from './stateutils';
import { logger } from './logger';

export function authReducer(state, action: PayloadAction): AuthState {
  switch (action.type) {
    case ActionTypes.LOGIN: {
      return {
        ...state,
        loading: true,
        user: null,
        error: null
      };
    }
    case ActionTypes.LOGIN_SUCCESS: {
      return {
        ...state,
        loading: false,
        user: action.payload,
        showLogin: false,
        error: null
      };
    }
    case ActionTypes.LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
        error: (action.payload && action.payload.error_description) || 'error.wrong_login_data'
      };
    }
    case ActionTypes.REGISTER: {
      return {
        ...state,
        loading: true,
        error: null
      };
    }
    case ActionTypes.REGISTER_SUCCESS: {
      const response = action.payload.response;
      return {
        ...state,
        loading: false,
        freeProjectReward: response.freeProjectReward,
        showRegister: {
          ...state.showRegister,
          step: 3
        },
        error: null
      };
    }
    case ActionTypes.REGISTER_FAILED: {
      return {
        ...state,
        loading: false,
        error: (action.payload && action.payload.Message) || 'error.user_exists'
      };
    }
    case ActionTypes.UPDATE_USER_IMAGE: {
      return {
        ...state,
        lastUpdate: Date.now()
      };
    }
    case ActionTypes.LOGOUT: {
      return {
        ...state,
        lastuser: state.user ? state.user.firstName : '',
        user: null,
        company: null
      };
    }
    case ActionTypes.TOGGLE_LOGIN: {
      return {
        ...state,
        showLogin: !state.showLogin,
        error: null,
        showRegister: {
          email: null,
          password: null,
          step: 0
        }
      };
    }
    case ActionTypes.TOGGLE_REGISTER: {
      return {
        ...state,
        showLogin: false,
        showRegister: action.payload,
        error: null
      };
    }
    case ActionTypes.LOAD_USER_SUCCESS: {
      return {
        ...state,
        user: {
          ...state.user,
          firstName: action.payload.firstName,
          lastName: action.payload.lastName,
          profileImage: action.payload.profileImage
        },
        userProfile: action.payload
      };
    }
    case ActionTypes.RESTORE_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case ActionTypes.RESTORE_PASSWORD_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: null
      };
    }
    case ActionTypes.RESTORE_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: (action.payload && action.payload.error_description) || 'error.invalid_user'
      };
    }
    case ActionTypes.RESET_PASSWORD: {
      return {
        ...state,
        error: null,
        loading: true
      };
    }
    case ActionTypes.RESET_PASSWORD_SUCCESS: {
      return {
        ...state,
        error: null,
        loading: false
      };
    }
    case ActionTypes.RESET_PASSWORD_FAILED: {
      return {
        ...state,
        loading: false,
        error: action.payload && action.payload.error.Message
      };
    }

    default: {
      return state;
    }
  }
}

export function appReducer(state, action) {
  switch (action.type) {
    case ActionTypes.CHANGED_LANGUAGE: {
      return {
        ...state,
        language: action.payload
      };
    }
    case ActionTypes.TOGGLE_EDIT: {
      return {
        ...state,
        editing: action.payload
      };
    }
    case ActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        modal: action.payload,
        modalComponent: action.component
      };
    }
    case ActionTypes.SHOW_MODAL: {
      return {
        ...state,
        modal: action.payload
      };
    }
    case ActionTypes.TOGGLE_LOGIN: {
      return {
        ...state,
        modal: null
      };
    }
    case ActionTypes.TOGGLE_REGISTER: {
      return {
        ...state,
        modal: null
      };
    }
    default: {
      return state;
    }
  }
}

export function profileReducer(state, action) {
  switch (action.type) {
    case ActionTypes.USER_SET: {
      return {
        ...state,
        user: action.payload
      };
    }

    default: {
      return state;
    }
  }
}

export const reducers: ActionReducerMap<AppState> = {
  auth: authReducer,
  app: appReducer,
  profile: profileReducer,
  routerReducer: fromRouter.routerReducer
};

export const metaReducers: MetaReducer<AppState>[] = environment.development ? [logger, asyncReducer] : [logger, asyncReducer];

// SELECTORS
export const getAuth = (state: AppState) => state.auth;
export const getAppState = (state: AppState) => state.app;

export const getUser = createSelector(getAuth, (state) => state.user);
export const lastUpdate = createSelector(getAuth, (state: AuthState) => state.lastUpdate);
export const getStateName = createSelector(
  getAppState,
  (state) =>
    state.routerState &&
    state.routerState.routerState &&
    state.routerState.routerState.root &&
    state.routerState.routerState.root.firstChild &&
    state.routerState.routerState.root.firstChild.firstChild &&
    state.routerState.routerState.root.firstChild.firstChild.data &&
    state.routerState.routerState.root.firstChild.firstChild.data.state
);

export const getAnchors = createSelector(getAppState, (state) => state.anchors);
export const getLanguage = createSelector(getAppState, (state) => {
  return state.language;
});
