<div class="container google">
    <div class="row">
        <div class="col-12">
            <h1>New Registration - Defibrillator Details</h1>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" #defiForm="ngForm">
        <div class="row">
            <div class="col-12 text-right">
                <div class=" pull-left google_warning" *ngIf="googlePlace.placeId">
                    <div *ngIf="googlePlace.placeId == 'no_address'">
                        <fa-icon [icon]="faExclamationTriangle" aria-hidden="true"></fa-icon>No Google address found!
                    </div>
                </div>
                <button type="button" class="btn btn-info pull-right" (click)="testLocation()">Test location (Google
                    maps)</button>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Place category</label>
                    <select class="form-control" id="type" required [(ngModel)]="model.place.type" name="type">
                        <option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="name">Name</label>
                    <input [readOnly]="model.place.type==11" type="text" class="form-control" id="name"
                        [required]="model.place.type!==11" [(ngModel)]="model.place.name" name="name">
                    <p *ngIf="googlePlace.placeId && model.place.name != googlePlace.placeName && model.place.type!=11">
                        {{googlePlace.placeName}}
                        <a class="" (click)="model.place.name = googlePlace.placeName" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>

                <div class="form-group">
                    <label for="street">Street</label>
                    <input type="text" class="form-control" id="street" required
                        [(ngModel)]="model.place.address.street" name="street">
                    <p *ngIf="googlePlace.placeId">
                        {{googlePlace.street}}
                        <a class="" (click)="model.place.address.street = googlePlace.street" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="street2">Street 2</label>
                    <input type="text" class="form-control" id="street2" [(ngModel)]="model.place.address.street2"
                        name="street2">
                </div>
                <div class="form-group">
                    <label for="zipCode">Zip code</label>
                    <input type="text" class="form-control" id="zipCode" required
                        [(ngModel)]="model.place.address.zipCode" name="zipCode">
                    <p *ngIf="googlePlace.placeId">
                        {{googlePlace.zipCode}}
                        <a class="" (click)="model.place.address.zipCode = googlePlace.zipCode" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="city">City</label>
                    <input type="text" class="form-control" id="city" required [(ngModel)]="model.place.address.city"
                        name="city">
                    <p *ngIf="googlePlace.placeId">
                        {{googlePlace.city}}
                        <a class="" (click)="model.place.address.city = googlePlace.city" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="locationDescription">Floor/Room/Place name</label>
                    <input type="text" id="locationDescription" class="form-control"
                        [(ngModel)]="model.locationDescription" name="locationDescription">
                </div>
                <div class="form-group">
                    <label for="state">State</label>
                    <input type="text" class="form-control" id="state" [(ngModel)]="model.place.address.state"
                        name="state">
                    <p *ngIf="googlePlace.placeId">
                        {{googlePlace.state}}
                        <a class="" (click)="model.place.address.state = googlePlace.state" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="country">Country</label>
                    <select class="form-control" [(ngModel)]="model.place.address.country" id="country" name="country"
                        required>
                        <option *ngFor="let c of countries" [value]="c.value">{{c.name}}</option>
                    </select>
                </div>
                <div class="form-group">
                    <label for="description">Place description (max. 200 characters)</label>
                    <textarea class="form-control" id="description" [(ngModel)]="model.place.description"
                        name="description" maxlength="200" rows="3"></textarea>
                </div>
                <div class="form-group check">
                    <label for="alwaysOpen">Always open (24h)</label>
                    <span>
                        <input type="checkbox" class="form-control move-left" id="alwaysOpen"
                            [(ngModel)]="model.alwaysOpen" name="alwaysOpen">
                        Please activate if the defibrillator is accessible at any time
                    </span>
                </div>
                <div class="form-group" *ngIf="googlePlace.placeId && googlePlace.openingHours">
                    <p>Google Opening Hours</p>
                    <div *ngFor="let openingHour of googlePlace.openingHours; let i = index;">
                        {{googlePlace.openingHours[i]}}
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="contactTitle">Contact title</label>
                    <input type="text" class="form-control" id="contactTitle" [(ngModel)]="model.place.contactTitle"
                        name="contactTitle">
                </div>
                <div class="form-group">
                    <label for="contactFirstName">Contact first name</label>
                    <input type="text" class="form-control" id="contactFirstName" [required]="model.place.type==11"
                        [(ngModel)]="model.place.contactFirstName" name="contactFirstName">
                </div>
                <div class="form-group">
                    <label for="contactLastName">Contact last name</label>
                    <input type="text" class="form-control" id="contactLastName" [required]="model.place.type==11"
                        [(ngModel)]="model.place.contactLastName" name="contactLastName">
                </div>
                <div class="form-group">
                    <label for="phone1">Phone 1</label>
                    <input type="text" class="form-control" id="phone1" [(ngModel)]="model.place.phone1" name="phone1">
                    <p *ngIf="googlePlace.placeId">
                        {{googlePlace.phoneNumber}}
                        <a class="" (click)="model.place.phone1 = googlePlace.phoneNumber" data-toggle="tooltip"
                            title="Set Google value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="phone2">Phone 2</label>
                    <input type="text" class="form-control" id="phone2" [(ngModel)]="model.place.phone2" name="phone2">
                </div>
                <div class="form-group">
                    <label for="mobile">Mobile</label>
                    <input type="text" class="form-control" id="mobile" [(ngModel)]="model.place.mobile" name="mobile">
                </div>
                <div class="form-group">
                    <label for="fax">Fax</label>
                    <input type="text" class="form-control" id="fax" [(ngModel)]="model.place.fax" name="fax">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="model.place.email" name="email">
                </div>
                <div class="form-group">
                    <label for="website">Website</label>
                    <input type="text" class="form-control" id="website" [(ngModel)]="model.place.website"
                        name="website">
                </div>
                <agm-map [latitude]="googlePlace.placeId ? googlePlace.latitude : lat"
                    [longitude]="googlePlace.placeId ? googlePlace.longitude : lng" [zoom]="zoom"
                    [disableDefaultUI]="false">
                    <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
                        [label]="m.label" [iconUrl]="m.icon" [markerDraggable]="m.draggable"
                        (dragEnd)="markerDragEnd(m, $event)">
                    </agm-marker>
                </agm-map>
                <h4>Defibrillator Position Data</h4>
                <div class="form-group">
                    <label for="latitude">Latitude</label>
                    <input *ngIf="markers" type="number" class="form-control" id="latitude"
                        (ngModelChange)="toggleButton()" [(ngModel)]="this.latitude" name="latitude">
                </div>
                <div class="form-group">
                    <label for="longitude">Longitude</label>
                    <input *ngIf="markers" type="number" class="form-control" id="longitude"
                        (ngModelChange)="toggleButton()" [(ngModel)]="this.longitude" name="longitude">
                </div>
                <button type="button" [disabled]="!buttonActive" class="btn btn-primary" (click)="setMarker()">Set
                    Marker</button>
                <button type="button" [disabled]="!resetButtonActive" class="btn btn-primary pull-right"
                    (click)="setMarkerToAddress()">Reset Marker To Address</button>
            </div>

        </div>

        <div class="row">
            <div class="col-12">
                <h4>Images (only the first 4 images are shown on map)</h4>
                <div *ngFor="let image of model.mediaFiles; let i = index;">
                    <div class="col-md-2">
                        <img [src]="apiUrl + '/api/defibrillator/image/' + image.id + '/300'" />
                        <a (click)="removeImage(i)">
                            <fa-icon [icon]="faMinusCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right" style="margin-top: 20px;">
                <button type="button" class="btn btn-secondary pull-right"
                    [routerLink]="['/registration']">Cancel</button>
                <button type="button" class="btn btn-danger pull-right" (click)="ignoreRequest()"
                    ng-really-message="Do you really want to ignore this request?"
                    [disabled]="this.submitted">Decline</button>
                <button type="submit" class="btn btn-success pull-right" [disabled]="this.submitted"
                    ng-really-message="Do you really want to delete this defibrillator?">Save</button>
            </div>
        </div>
    </form>
</div>