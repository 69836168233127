import { PlaceCategoryList } from "./place-category.model";
import { User } from "./user.model"

interface Serializable<T> {
    deserialize(input: Object): T;
}

export class Defi implements Serializable<Defi> {
    public id: string;
    public name: string;
    public locationDescription: string;
    public alwaysOpen: boolean;
    public confirmed: boolean;
    public published: boolean;
    public syncPartner: string;
    public syncPartnerId: string;
    public manufacturer: string;
    public modelName: string;
    public serialNumber: string;
    public warrantyInYears: number;
    public purchasedOn: string;
    public initialOperationOn: string;
    public isUnderRegulation: string;
    public nextInspection: string;
    public pad1Expiration: string;
    public pad1ExpirationReminder: string;
    public pad1SerialNumber: string;
    public pad1LotNumber: string;
    public pad2Expiration: string;
    public pad2ExpirationReminder: string;
    public pad2SerialNumber: string;
    public pad2LotNumber: string;
    public batteryExpiration: string;
    public batteryExpirationReminder: string;
    public batterySerialNumber: string;
    public batteryLotNumber: string;

    public location: Location;
    public owner: User;
    public ownerDeputy: User;

    public place: Place;

    public mediaFiles: Array<MediaFile>;

    // auto property
    get displayName(): string {
        if (this.name)
            return this.name;
        else if (this.place.name)
            return this.place.name;
        else if (this.place.type == 11)
        {
            let name = "";
            if (this.place.contactTitle)
                name += this.place.contactTitle + " ";
            if (this.place.contactFirstName)
                name += this.place.contactFirstName + " ";
            if (this.place.contactLastName)
                name += this.place.contactLastName;
            return "(" + name + ")";
        }
        return null;
    }

    constructor() {
        this.published = true;
        this.place = new Place();
        this.owner = new User();
        this.ownerDeputy = new User();
        this.location = new Location();
        this.mediaFiles = new Array<MediaFile>();
    }

    deserialize(input) {
        this.id = input.id;
        this.name = input.name;
        this.locationDescription = input.locationDescription;
        this.alwaysOpen = input.alwaysOpen;
        this.confirmed = input.confirmed;
        this.published = input.published;
        this.syncPartner = input.syncPartner;
        this.syncPartnerId = input.syncPartnerId;
        this.manufacturer = input.manufacturer;
        this.modelName = input.modelName;
        this.serialNumber = input.serialNumber;
        this.warrantyInYears = input.warrantyInYears;
        this.purchasedOn = input.purchasedOn;
        this.initialOperationOn = input.initialOperationOn;
        this.isUnderRegulation = input.isUnderRegulation;
        this.nextInspection = input.nextInspection;
        this.pad1Expiration = input.pad1Expiration;
        this.pad1ExpirationReminder = input.pad1ExpirationReminder;
        this.pad1SerialNumber = input.pad1SerialNumber;
        this.pad1LotNumber = input.pad1LotNumber;
        this.pad2Expiration = input.pad2Expiration;
        this.pad2ExpirationReminder = input.pad2ExpirationReminder;
        this.pad2SerialNumber = input.pad2SerialNumber;
        this.pad2LotNumber = input.pad2LotNumber;
        this.batteryExpiration = input.batteryExpiration;
        this.batteryExpirationReminder = input.batteryExpirationReminder;
        this.batterySerialNumber = input.batterySerialNumber;
        this.batteryLotNumber = input.batteryLotNumber;

        this.place = new Place().deserialize(input.place);

        if (input.owner) {
            this.owner = new User().deserialize(input.owner);
        }

        if (input.ownerDeputy) {
            this.ownerDeputy = new User().deserialize(input.ownerDeputy);
        }


        if (input.location) {
            this.location = new Location().deserialize(input.location);
        }

        this.mediaFiles = new Array<MediaFile>();
        for (let entry of input.mediaFiles) {
            this.mediaFiles.push(new MediaFile().deserialize(entry));
        }

        return this;
    }
}

export class MediaFile implements Serializable<MediaFile> {
    public id: string;
    public fileType: string;

    deserialize(input) {
        this.id = input.id;
        this.fileType = input.fileType;

        return this;
    }
}


export class Place implements Serializable<Place> {
    public id: string;
    public name: string;
    public description: string;
    public type: number;
    public contactTitle: string;
    public contactFirstName: string;
    public contactLastName: string;
    public phone1: string;
    public phone2: string;
    public mobile: string;
    public fax: string;
    public email: string;
    public website: string;
    public googlePlaceId: string;


    // auto property
    get typeName(): string {
        if (!this.type && this.type !== 0 && this.type > 13)
            return "";
        let cat = PlaceCategoryList.getCategoryList()[this.type];
        if (!cat)
            return "";
        return cat.name;
    }

    public address: Address;

    constructor() {
        this.address = new Address();
    }

    deserialize(input) {
        this.id = input.id;
        this.name = input.name;
        this.description = input.description;
        this.type = input.type;
        this.contactTitle = input.contactTitle;
        this.contactFirstName = input.contactFirstName;
        this.contactLastName = input.contactLastName;
        this.phone1 = input.phone1;
        this.phone2 = input.phone2;
        this.mobile = input.mobile;
        this.fax = input.fax;
        this.email = input.email;
        this.website = input.website;
        this.googlePlaceId = input.googlePlaceId;

        this.address = new Address().deserialize(input.address);

        return this;
    }
}

export class Address implements Serializable<Address> {
    public id: string;
    public street: string;
    public street2: string;
    public zipCode: string;
    public city: string;
    public state: string;
    public country: string;

    // auto property
    get countryDisplayName(): string {
        if (!this.country)
            return "";
        let country = Countries.find(x => x.value.toLowerCase() == this.country.toLowerCase());
        if (!country)
            return this.country;
        return country.name;
    }

    public location: Location;

    constructor() {
        this.location = new Location();
    }

    deserialize(input) {
        this.id = input.id;
        this.street = input.street;
        this.street2 = input.street2;
        this.zipCode = input.zipCode;
        this.city = input.city;
        this.state = input.state;
        this.country = input.country;

        this.location = new Location().deserialize(input.location);

        return this;
    }
}

export class Location implements Serializable<Location> {
    public id: string;
    public latitude: number;
    public longitude: number;

    deserialize(input) {
        this.id = input.id;
        this.latitude = input.latitude;
        this.longitude = input.longitude;

        return this;
    }
}


let Countries = [
    { value: 'AE', name: "United Arab Emirates" },
    { value: 'AT', name: "Austria" },
    { value: 'AU', name: "Australia" },
    { value: 'BA', name: "Bosnia and Herzegovina" },
    { value: 'BE', name: "Belgium" },
    { value: 'BG', name: "Bulgaria" },
    { value: 'BR', name: "Brazil" },
    { value: 'CA', name: "Canada" },
    { value: 'CH', name: "Swiss" },
    { value: 'CN', name: "China" },
    { value: 'CZ', name: "Czechia" },
    { value: 'DE', name: "Germany" },
    { value: 'DK', name: "Denmark" },
    { value: 'EE', name: "Estonia" },
    { value: 'EG', name: "Egypt" },
    { value: 'ES', name: "Spain" },
    { value: 'FI', name: "Finland" },
    { value: 'FJ', name: "Fiji" },
    { value: 'FR', name: "France" },
    { value: 'GB', name: "United Kingdom" },
    { value: 'GR', name: "Greece" },
    { value: 'HR', name: "Croatia" },
    { value: 'HU', name: "Hungary" },
    { value: 'ID', name: "Indonesia" },
    { value: 'IE', name: "Ireland" },
    { value: 'IN', name: "India" },
    { value: 'IS', name: "Iceland" },
    { value: 'IT', name: "Italy" },
    { value: 'JP', name: "Japan" },
    { value: 'KE', name: "Kenya" },
    { value: 'KH', name: "Cambodia" },
    { value: 'KR', name: "Korea, Republic of" },
    { value: 'KZ', name: "Kazakhstan" },
    { value: 'LT', name: "Lithuania" },
    { value: 'MX', name: "Mexico" },
    { value: 'NZ', name: "New Zealand" },
    { value: 'LU', name: "Luxembourg" },
    { value: 'LV', name: "Latvia" },
    { value: 'NL', name: "Netherlands" },
    { value: 'NO', name: "Norway" },
    { value: 'PL', name: "Poland" },
    { value: 'PT', name: "Portugal" },
    { value: 'RS', name: "Serbia" },
    { value: 'RU', name: "Russia" },
    { value: 'SE', name: "Sweden" },
    { value: 'SG', name: "Singapore" },
    { value: 'SI', name: "Slovenia" },
    { value: 'SK', name: "Slovakia" },
    { value: 'SL', name: "Sierra Leone" },
    { value: 'SN', name: "Senegal" },
    { value: 'TH', name: "Thailand" },
    { value: 'TR', name: "Turkey" },
    { value: 'TW', name: "Taiwan, Province of China" },
    { value: 'TZ', name: "Tanzania, United Republic of" },
    { value: 'UA', name: "Ukraine" },
    { value: 'UG', name: "Uganda" },
    { value: 'US', name: "United States of America" },
    { value: 'VN', name: "Vietnam" },
    { value: 'ZA', name: "South Africa" }
];
export { Countries };