import { Injectable, Injector } from '@angular/core';
import {
  HttpHandler,
  HttpRequest,
  HttpSentEvent,
  HttpErrorResponse,
  HttpResponse,
  HttpProgressEvent,
  HttpHeaderResponse,
  HttpInterceptor,
  HttpUserEvent
} from '@angular/common/http';
import { Store } from '@ngrx/store';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, switchMap, filter, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { AuthService } from './auth';
import { AppState } from '../state/app.state';
import { LogoutAction } from '../state/app.actions';
import { globals } from '../utils';

@Injectable()
export class InterceptedHttp implements HttpInterceptor {
  origin = environment.origin;

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
    // analyze target
    const targetApi = request.url.indexOf('/api/') >= 0;
    const targetSearch = request.url.indexOf('/search/') >= 0;
    // update url of API and search
    if (targetApi || targetSearch) {
      const newUrl = this.updateUrl(request.url);
      request = request.clone({
        url: newUrl
      });
    }

    // for non API requests, fire now
    const authService = this.injector.get(AuthService);
    if (!targetApi) {
      // logged in
      return next.handle(request);
    }

    // add authorization to header
    if (authService.token) {
      request = this.addToken(request, authService.token);
    }

    console.log('[API - REQUEST] ', request.method, request.url, request.body);

    return next.handle(request).pipe(
      catchError(error => {
        // 401, but token exists
        if (error instanceof HttpErrorResponse && error.status === 401 && authService.token && globals.isBrowser) {
          console.log('401 handling started');
          return this.handle401Error(request, next);
        } else {
          console.log('session terminated, logging out');
          return throwError(error);
        }
      })
    );
  }

  constructor(private injector: Injector, private store: Store<AppState>) {
    this.errorHandler = this.errorHandler.bind(this);
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`
      }
    });
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const authService = this.injector.get(AuthService);
      return authService.refreshToken().pipe(
        switchMap(() => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(authService.token);
          if (authService.token) {
            // refresh worked, fire request again
            return next.handle(this.addToken(request, authService.token));
          } else {
            // refresh failed, logout user
            this.store.dispatch(new LogoutAction());
          }
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  private updateUrl(url: string) {
    if (url.match(/^(http|https):\/\//)) {
      return url;
    }
    if (url.indexOf('assets') >= 0 && url.indexOf('assets') < 2) {
      return url;
    }
    return this.origin + url;
  }

  errorHandler(error) {
    if (error.json) {
      error = error.json();
    }
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    let errorMessage = error.errorMessage || error.error || error.toString();
    if (error.code === 12) {
      errorMessage = 'Server unreachable';
    }
    return throwError(error);
  }
}
