import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { ApiService } from '../../services/index';
import { Defi } from '../../models/defi.model';
import { ImageRequest } from '../../models/image-request.model';
import {
  PlaceCategory,
  PlaceCategoryList,
} from '../../models/place-category.model';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-image-request-form',
  styleUrls: ['./image-request-form.component.scss'],
  templateUrl: './image-request-form.component.html',
})
export class ImageRequestFormComponent implements OnInit {
  model: Defi = new Defi();
  request: ImageRequest = new ImageRequest();
  image: string;

  submitted = false;

  categories: Array<PlaceCategory> = PlaceCategoryList.getCategoryList();

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => this.api.getDefiImageRequest(params.id))
      )
      .subscribe((response) => {
        // existing defi from service
        this.request = new ImageRequest().deserialize(response);
        console.log('this.request', this.request);
        this.model = this.request.defi;

        this.api
          .getDefiImageRequestImage(this.request)
          .subscribe((imageResponse) => {
            const arrayBuffer = imageResponse.arrayBuffer();
            this.image = this.arrayBufferToBase64(arrayBuffer);
          });
      });
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // confirm delete request
    this.api.postDefiImageRequest(this.request).subscribe((response) => {
      this.router.navigate(['image']);
    });
  }

  ignoreRequest() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // delete the request
    this.api.deleteDefiImageRequest(this.request).subscribe((response) => {
      this.router.navigate(['image']);
    });
  }

  arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
  }
}
