import { Component, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { NgModalConfirm } from '../../shared/modal';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

import { ApiService } from '../../services/index';

import { User } from '../../models/user.model';
import { Countries } from '../../models/defi.model';
import { catchError, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-user-form',
  styleUrls: ['./user-form.component.scss'],
  templateUrl: './user-form.component.html',
})
export class UserFormComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  public isNewUser = false;
  model: User = new User();
  countries = Countries;
  partners = User.Partners;
  modalRef;

  @Input() yes: any;

  roles: Array<string>;

  submitted = false;

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) =>
          params.id == 'new'
            ? Observable.throw(null)
            : this.api.getAdmin(params.id)
        )
      )
      .pipe(
        catchError((e) => {
          // new defi
          this.isNewUser = true;
          this.model = new User();
          return EMPTY;
        })
      )
      .subscribe((user) => {
        // existing defi from service
        this.isNewUser = false;
        this.model = new User().deserialize(user);
      });
  }

  trackByFn(index: any, item: any) {
    return index;
  }

  addCountry() {
    this.model.adminCountries.push('');
  }

  removeCountry(index: number) {
    this.model.adminCountries.splice(index, 1);
  }

  addPartner() {
    this.model.adminPartners.push('');
  }

  removePartner(index: number) {
    this.model.adminPartners.splice(index, 1);
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // send changed to API and redirect back
    if (this.isNewUser) {
      // post new defi
      this.api.postAdmin(this.model).subscribe((response) => {
        this.router.navigate(['useradmin']);
      });
    } else {
      // put existing defi
      this.api.putAdmin(this.model).subscribe((response) => {
        this.router.navigate(['useradmin']);
      });
    }
  }

  deleteUser() {
    if (!this.isNewUser) {
      // set the form to submitted to avoid double-firing
      this.submitted = true;

      // delete existing defi
      this.api.deleteAdmin(this.model).subscribe((response) => {
        this.router.navigate(['useradmin']);
      });
    }
  }

  confirmDelete() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(NgModalConfirm, ngbModalOptions);
    modalRef.result.then(
      (result) => {
        this.deleteUser();
      },
      () => {}
    );
  }
}
