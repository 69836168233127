export function downloadBlob(res, name?) {
    const blob = res.body;
    const contentDisposition = res.headers.get('content-disposition');
    let filename = name;
    if (contentDisposition) {
        filename = contentDisposition.match(/filename=["]{0,1}((=\?utf-8\?B\?([a-z 0-9]+)=)|([^\\/:"*?<>|]+))/i);
        if (filename && filename[3]) {
            filename = b64DecodeUnicode(filename[3]);
        } else if (filename) {
            filename = filename[1];
        } else {
            filename = name;
        }
    }

    if (window.navigator && window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(blob, filename);
    } else {
        const url = window.URL.createObjectURL(blob);

        // create hidden dom element (so it works in all browsers)
        const a = document.createElement('a');
        a.setAttribute('style', 'display:none;');
        document.body.appendChild(a);

        // create file, attach to hidden element and open hidden element
        a.href = url;
        a.download = filename;
        a.click();
    }
    return res;
}

function b64DecodeUnicode(str) {
    return decodeURIComponent(
        Array.prototype.map
            .call(atob(str), function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            })
            .join('')
    );
}