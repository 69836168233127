import { Component } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ApiService, Shared } from '../../services/index';

import { Defi, Countries } from '../../models/defi.model';
import { GooglePlace } from '../../models/google-place.model';
import { RegistrationRequest } from '../../models/registration-request.model';
import {
  PlaceCategory,
  PlaceCategoryList,
} from '../../models/place-category.model';
import { switchMap } from 'rxjs/operators';
import {
  faPlusCircle,
  faCheckCircle,
  faMinusCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-defi-registration-request-form',
  styleUrls: ['./registration-request-form.component.scss'],
  templateUrl: './registration-request-form.component.html',
})
export class RegistrationRequestFormComponent {
  // fontawesome
  faPlusCircle = faPlusCircle;
  faCheckCircle = faCheckCircle;
  faMinusCircle = faMinusCircle;
  faExclamationTriangle = faExclamationTriangle;

  model: Defi = new Defi();
  request: RegistrationRequest = new RegistrationRequest();
  googlePlace: GooglePlace = new GooglePlace();
  lat: number;
  lng: number;
  markers;
  longitude;
  latitude;
  marker = [];
  countries = Countries;
  zoom = 18;
  submitted = false;
  buttonActive = false;
  resetButtonActive;

  apiUrl: string = Shared.apiUrl;

  categories: Array<PlaceCategory> = PlaceCategoryList.getCategoryList();

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {}

  testLocation() {
    // load location
    this.api.testLocation(this.model).subscribe((response) => {
      if (response != null) {
        this.googlePlace = new GooglePlace().deserialize(response);
        this.loadMarkers();

        this.lat = this.googlePlace.latitude;
        this.lng = this.googlePlace.longitude;

        this.loadMarkers();
        if (this.lat != this.latitude || this.lng != this.longitude) {
          this.resetButtonActive = true;
        }
      } else {
        this.googlePlace = new GooglePlace();
        this.googlePlace.placeId = 'no_address';
      }
    });
  }

  toggleButton() {
    if (this.lat == this.latitude && this.lng == this.longitude) {
      this.buttonActive = false;
      this.resetButtonActive = false;
    } else {
      this.buttonActive = true;
      this.resetButtonActive = true;
    }
  }

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) =>
          this.api.getDefiRegistrationRequest(params.id)
        )
      )
      .subscribe((response: any) => {
        // existing defi from service
        this.request = new RegistrationRequest().deserialize(response);
        this.model = this.request.newDefi;

        this.testLocation();

        if (response.locationLatitute) {
          this.latitude = response.locationLatitute;
          this.longitude = response.locationLongitude;
        } else {
          this.latitude = this.googlePlace.latitude;
          this.longitude = this.googlePlace.longitude;
        }
      });
  }

  loadMarkers() {
    this.markers = this.marker = [
      {
        lat: this.googlePlace.placeId ? this.googlePlace.latitude : this.lat,
        lng: this.googlePlace.placeId ? this.googlePlace.longitude : this.lng,
        icon: '/assets/home.png',
        draggable: false,
      },
      {
        lat: this.latitude ? this.latitude : this.lat,
        lng: this.longitude ? this.longitude : this.lng,
        icon: '/assets/defi.png',
        draggable: true,
      },
    ];
  }

  markerDragEnd(m: marker, event: google.maps.MouseEvent) {
    // convert to get coords
    const eventStr = JSON.stringify(event);
    const coords = JSON.parse(eventStr);
    // get corrds
    this.latitude = coords.latLng.lat;
    this.longitude = coords.latLng.lng;
    this.buttonActive = false;
    this.resetButtonActive = true;
  }

  setMarker() {
    this.markers.pop();
    this.markers.push({
      lat: this.latitude,
      lng: this.longitude,
      icon: '/assets/defi.png',
      draggable: true,
    });
    this.buttonActive = false;
    this.resetButtonActive = true;
  }

  setMarkerToAddress() {
    this.markers.pop();
    this.markers.push({
      lat: this.googlePlace.placeId ? this.googlePlace.latitude : this.lat,
      lng: this.googlePlace.placeId ? this.googlePlace.longitude : this.lng,
      icon: '/assets/defi.png',
      draggable: true,
    });

    this.latitude = this.googlePlace.placeId
      ? this.googlePlace.latitude
      : this.lat;
    this.longitude = this.googlePlace.placeId
      ? this.googlePlace.longitude
      : this.lng;

    this.buttonActive = false;
    this.resetButtonActive = false;
  }

  removeImage(index: number) {
    this.model.mediaFiles.splice(index, 1);
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // set model location on save
    if (this.lat == this.latitude && this.lng == this.longitude) {
      this.model.location = null;
    } else {
      this.model.location.latitude = this.latitude;
      this.model.location.longitude = this.longitude;
    }

    // confirm delete request
    this.api
      .postDefiRegistrationRequest(this.request, this.model)
      .subscribe((response) => {
        this.router.navigate(['registration']);
      });
  }

  ignoreRequest() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // delete the request
    this.api
      .deleteDefiRegistrationRequest(this.request)
      .subscribe((response) => {
        this.router.navigate(['registration']);
      });
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
