import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState, AuthState, LoginAction } from 'src/app/state';

import { AuthService } from '../../services/index';

@Component({
  templateUrl: 'login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  model: any = {};
  loading = false;
  errorOccured = false;
  returnUrl: string;
  subscription: any;

  constructor(
    private store: Store<AppState>,
    private route: ActivatedRoute,
    private authService: AuthService
  ) {
    this.subscription = this.store
      .select('auth')
      .subscribe((state: AuthState) => {
        this.loading = state.loading;
        this.errorOccured = Boolean(state.error);
        this.loading = false;
      });
  }

  ngOnInit() {
    // reset login status
    this.authService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || '/';
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  login() {
    this.loading = true;
    this.errorOccured = false;
    this.store.dispatch(
      new LoginAction({
        username: this.model.username,
        password: this.model.password,
        // onSuccess: this.toggleLogin(),
        onSuccess: 'redirect',
      })
    );
  }
}
