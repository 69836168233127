import { Component } from '@angular/core';

import { AuthService, GlobalEventsManager } from '../../services/index';

@Component({
  selector: 'app-toh-nav',
  styleUrls: ['./nav.component.scss'],
  templateUrl: './nav.component.html',
})
export class NavComponent {
  showNavBar = false;

  constructor(
    public authService: AuthService,
    private globalEventsManager: GlobalEventsManager
  ) {
    this.globalEventsManager.showNavBar.subscribe((mode: any) => {
      this.showNavBar = mode;
    });

    this.globalEventsManager.hideNavBar.subscribe((mode: any) => {
      this.showNavBar = false;
    });
  }
}
