import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { ApiService, AuthService } from '../../services/index';
import { Defi, Countries } from '../../models/defi.model';
import { downloadBlob } from '../../shared/utils';
import { faSearch, faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-defi-list',
  styleUrls: ['./defi-list.component.scss'],
  templateUrl: './defi-list.component.html',
})
export class DefiListComponent implements OnInit {
  // font awesome
  public faSearch = faSearch;
  public faEdit = faEdit;

  // loading indicator
  loading = false;

  // current sorting
  sort: any;

  // current filter
  filter: string;
  filterChanged: Subject<string> = new Subject<string>();

  // export filters
  countries = Countries;
  exportFilterCountryCode: string;
  exportFilterZip: string;

  // The number of elements in the page
  size = 0;
  // The current page number
  pageNumber = 0;
  // The total number of elements
  totalElements = 0;
  // The total number of pages
  totalPages = 0;

  @ViewChild('companyNameItemTmpl', { static: true })
  companyNameItemTmpl: TemplateRef<any>;
  @ViewChild('placeAlwaysOpenTmpl', { static: true })
  placeAlwaysOpenTmpl: TemplateRef<any>;
  @ViewChild('editItemTmpl', { static: true }) editItemTmpl: TemplateRef<any>;

  // column definition
  columns;

  data: Array<Defi>;

  constructor(private api: ApiService, public auth: AuthService) {
    this.size = 20;
    this.filter = '';

    this.filterChanged
      .pipe(debounceTime(300)) // wait 300ms after the last event before emitting last event
      // .distinctUntilChanged() // only emit if value is different from previous value
      .subscribe((filter) => {
        // update filter
        this.filter = filter;
        // reset paging
        this.pageNumber = 0;

        // set loading indicator
        this.loading = true;

        // load data
        this.loadData();
      });
  }

  ngOnInit() {
    this.columns = [
      {
        prop: 'name',
        name: 'Company / Name',
        cellTemplate: this.companyNameItemTmpl,
        width: 250,
      },
      { prop: 'place.typeName', name: 'Category', width: 100 },
      { prop: 'place.address.street', name: 'Street', width: 180 },
      { prop: 'place.address.zipCode', name: 'Zip', width: 50 },
      { prop: 'place.address.city', name: 'City', width: 70 },
      { prop: 'place.address.countryDisplayName', name: 'Country', width: 70 },
      // { prop: 'place.alwaysOpen', name: '24h', cellTemplate: this.placeAlwaysOpenTmpl, width: 30 },
      { name: 'Actions', cellTemplate: this.editItemTmpl, width: 30 },
    ];

    this.sort = { prop: 'name', dir: 'asc' };
    this.setPage({ offset: 0 });
  }

  private loadData() {
    // load data (take, skip)
    this.api
      .getDefisPagedSortedFiltered(
        this.size,
        this.pageNumber * this.size,
        this.sort.prop,
        this.sort.dir,
        this.filter
      )
      .subscribe((response) => {
        // store the data
        this.data = new Array<Defi>();
        for (const entry of response.data) {
          this.data.push(new Defi().deserialize(entry));
        }

        // store the number of total elements
        this.totalElements = response.count;
        // calculate the number of total pages
        this.totalPages = Math.ceil(this.totalElements / this.size);

        // loading finished
        this.loading = false;
      });
  }

  /**
   * Populate the table with new data based on the page number
   * @param page The page to select
   */
  setPage(pageInfo) {
    // store requested page
    this.pageNumber = pageInfo.offset;

    // enable loading indicator
    this.loading = true;

    // load new data
    this.loadData();
  }

  onSort(event) {
    // store requested sort
    this.sort = event.sorts[0];

    // enable loading indicator
    this.loading = true;

    // load new data
    this.loadData();
  }

  filterUpdated(event) {
    // store requested filter (debounce)
    this.filterChanged.next(event.target.value.toLowerCase());
  }

  excelExport() {
    if (!this.exportFilterCountryCode) {
      alert('Please select country');
      return;
    }
    if (!this.exportFilterZip || this.exportFilterZip.length < 2) {
      alert('Please specify at least 2 ZIP digits');
      return;
    }

    console.log(
      'Export',
      this.exportFilterCountryCode + ' / ' + this.exportFilterZip
    );
    this.api
      .getDefisExportExcel(this.exportFilterCountryCode, this.exportFilterZip)
      .subscribe(downloadBlob);
  }
}
