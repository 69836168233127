<div class="container">
    <div class="row">
        <div class="col-md-6 offset-md-3">
            <h2>Password reset</h2>
            <form name="form" (ngSubmit)="f.form.valid && resetPassword()" #f="ngForm" novalidate
                *ngIf="requestState != 2">
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !username.valid }">
                    <label for="username">Email</label>
                    <input type="text" class="form-control" name="username" [(ngModel)]="model.username"
                        #username="ngModel" required />
                    <div *ngIf="f.submitted && !username.valid" class="help-block">Email is required</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <label for="password">Code</label>
                    <input type="text" class="form-control" name="code" [(ngModel)]="model.code" #code="ngModel"
                        required />
                    <div *ngIf="f.submitted && !code.valid" class="help-block">Code is required</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <label for="password">Password</label>
                    <input type="password" class="form-control" name="password" [(ngModel)]="model.password"
                        #password="ngModel" required />
                    <div *ngIf="f.submitted && !password.valid" class="help-block">Password is required</div>
                </div>
                <div class="form-group" [ngClass]="{ 'has-error': f.submitted && !password.valid }">
                    <label for="password2">Confirm password</label>
                    <input type="password" class="form-control" name="confirmPassword"
                        [(ngModel)]="model.confirmPassword" validateEqual="password" #confirmPassword="ngModel"
                        required />
                    <div *ngIf="f.submitted && !confirmPassword.valid" class="help-block">Passwords do not match</div>
                </div>
                <div class="form-group">
                    <button [disabled]="loading" class="btn btn-primary">Reset password now</button>
                    <img *ngIf="loading"
                        src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                </div>
            </form>
            <div *ngIf="requestState == 2">
                Your password was successfully reset. Please login with your new password now.
                <button type="button" class="btn btn-secondary pull-right" [routerLink]="['/login']">Go back to
                    login</button>
            </div>
            <div *ngIf="requestState == 3">
                An error occured, please try again. If it still doesn't work, get a new password restore email:
                <button type="button" class="btn btn-secondary pull-right" [routerLink]="['/forgot-password']">I forgot
                    my password</button>
            </div>
        </div>
    </div>
</div>