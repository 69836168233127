import { RouterReducerState } from '@ngrx/router-store';
import { getUser, AsyncState } from './stateutils';

export interface AuthState {
  user: User;
  userProfile: any;
  company: any;
  error: string;
  remember: boolean;
  loading: boolean;
  lastUpdate: number;
  showLogin: boolean;
  showRegister: {
    email: string;
    password: string;
    step: number;
  };
  userImage: string;
  freeProjectReward: boolean;
}

export interface ApplicationState {
  language: string;
  editing: string;
  slide: string;
  modal: string;
  modalComponent: string;
  routerState: any;
  anchors: string[];
}

export interface ProfileState {
  profileSearchFillRate: {
    result: {
      currentFillRate: number;
      nextFillEntity: string;
      nextFillEntityPercent: number;
      profileCreated: boolean;
      profilePictureSet: boolean;
      contactSet: boolean;
      jobSet: boolean;
    };
    pending: boolean;
  };
  id: string;
  experience: any;
  education: any;
  certificate: any;
  awards: any;
  membership: any;
  inviteRewards: any;
  profileFillRate: {
    result: {
      profileCreated: boolean;
      nameSet: boolean;
      profilePictureSet: boolean;
      contactSet: boolean;
      jobSet: boolean;
      educationSet: boolean;
      languageSet: boolean;
      certificateAwardMembershipSet: boolean;
      specialisationServicesSet: boolean;
      projectCreated: boolean;
      currentFillRate: number;
      nextFillEntity: string;
      nextFillEntityPercent: number;
      includedInSearch: boolean;
    };
    pending: boolean;
    error: any;
  };
  engagement: AsyncState;
  visitors: { who: AsyncState; data: AsyncState };
  social: {
    status: AsyncState;
    facebook: AsyncState;
    linkedin: AsyncState;
    twitter: AsyncState;
  };
  viewMode: any;
  isPrivacy: boolean;
}

export interface User {
  firstName: string;
  lastName: string;
  userId: string;
  image: string;
}

export interface Entity {
  id: string;
  name: string;
}

export interface AppState {
  auth: AuthState;
  app: ApplicationState;
  profile: ProfileState;
  routerReducer: RouterReducerState;
}

const initialAuthState: AuthState = {
  user: null,
  userProfile: null,
  company: null,
  error: '',
  loading: false,
  remember: false,
  lastUpdate: Date.now(),
  showLogin: false,
  showRegister: {
    email: null,
    password: null,
    step: 0
  },
  userImage: null,
  freeProjectReward: false
};

const initialAppState: ApplicationState = {
  language: 'de',
  editing: '',
  slide: '',
  modal: null,
  modalComponent: null,
  routerState: null,
  anchors: null
};

const initialProfileState: ProfileState = {
  id: null,
  experience: null,
  education: null,
  certificate: null,
  membership: null,
  inviteRewards: null,
  awards: null,
  profileSearchFillRate: null,
  profileFillRate: null,
  engagement: null,
  visitors: {
    who: null,
    data: null
  },
  viewMode: null,
  social: null,
  isPrivacy: null
};

export function getInitialState() {
  const auth = initialAuthState;
  auth.user = getUser();

  const state = {
    app: initialAppState,
    auth,
    profile: initialProfileState
  };
  return state;
}
