import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ApiService } from '../../services/index';

import { FirstresponderRequest } from '../../models/firstresponder-request.model';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-firstresponder-request-form',
  styleUrls: ['./firstresponder-request-form.component.scss'],
  templateUrl: './firstresponder-request-form.component.html',
})
export class FirstresponderRequestFormComponent implements OnInit {
  public request: FirstresponderRequest = new FirstresponderRequest();

  submitted = false;

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) =>
          this.api.getFirstresponderRequest(params.id)
        )
      )
      .subscribe((response) => {
        // existing defi from service
        this.request = new FirstresponderRequest().deserialize(response);
      });
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // confirm delete request
    this.api.postFirstresponderRequest(this.request).subscribe((response) => {
      this.router.navigate(['firstresponderrequest']);
    });
  }

  ignoreRequest() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // delete the request
    this.api.deleteFirstresponderRequest(this.request).subscribe((response) => {
      this.router.navigate(['firstresponderrequest']);
    });
  }
}
