import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';

import { ApiService } from '../../services/index';

import { Defi } from '../../models/defi.model';
import { DeleteRequest } from '../../models/delete-request.model';
import { GooglePlace } from '../../models/google-place.model';
import {
  PlaceCategory,
  PlaceCategoryList,
} from '../../models/place-category.model';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-defi-form',
  styleUrls: ['./delete-request-form.component.scss'],
  templateUrl: './delete-request-form.component.html',
})
export class DeleteRequestFormComponent implements OnInit {
  model: Defi = new Defi();
  request: DeleteRequest = new DeleteRequest();
  googlePlace: GooglePlace = new GooglePlace();
  lat: number;
  lng: number;
  markers;
  longitude;
  latitude;

  marker = [];

  zoom = 18;
  submitted = false;

  categories: Array<PlaceCategory> = PlaceCategoryList.getCategoryList();

  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) => this.api.getDefiDeleteRequest(params.id))
      )
      .subscribe((response) => {
        // existing defi from service
        this.request = new DeleteRequest().deserialize(response);
        this.model = this.request.defi;
        // marker data
        this.lat = this.model.place.address.location.latitude;
        this.lng = this.model.place.address.location.longitude;
        this.latitude = this.model.location.latitude;
        this.longitude = this.model.location.longitude;
        this.loadMarkers();
      });
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // confirm delete request
    this.api.postDefiDeleteRequest(this.request).subscribe((response) => {
      this.router.navigate(['deletion']);
    });
  }

  ignoreRequest() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // delete the request
    this.api.deleteDefiDeleteRequest(this.request).subscribe((response) => {
      this.router.navigate(['deletion']);
    });
  }

  loadMarkers() {
    this.markers = this.marker = [
      {
        lat: this.googlePlace.placeId ? this.googlePlace.latitude : this.lat,
        lng: this.googlePlace.placeId ? this.googlePlace.longitude : this.lng,
        icon: '/assets/home.png',
        draggable: false,
      },
      {
        lat: this.latitude ? this.latitude : this.lat,
        lng: this.longitude ? this.longitude : this.lng,
        icon: '/assets/defi.png',
        draggable: false,
      },
    ];
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
