import { User } from "./user.model"
import { Countries } from "./defi.model"

interface Serializable<T> {
    deserialize(input: Object): T;
}

export class FirstresponderRequest implements Serializable<FirstresponderRequest> {
    public id: string;
    public country: string;
    public qualificationType: number;
    public mobilePhone: string;
    public submitted: Date;

    public user: User;

    // auto property
    get countryDisplayName(): string {
        if (!this.country)
            return "";
        let country = Countries.find(x => x.value.toLowerCase() == this.country.toLowerCase());
        if (!country)
            return this.country;
        return country.name;
    }

    get qualificationTypeDisplayName(): string {
        if (!this.qualificationType)
            return "";
        let qualificationType = QualificationTypes.find(x => x.value == this.qualificationType);
        if (!qualificationType)
            return this.qualificationType.toString();
        return qualificationType.name;
    }

    constructor() {
        this.user = new User();
    }

    deserialize(input) {
        this.id = input.id;
        this.country = input.country;
        this.qualificationType = input.qualificationType;
        this.mobilePhone = input.mobilePhone;
        this.submitted = input.submitted;

        this.user = new User().deserialize(input.profile);

        return this;
    }
}

let QualificationTypes = [
    { value: 0, name: "Doctor" },
    { value: 1, name: "Nurse" },
    { value: 2, name: "Critical care paramedic" },
    { value: 3, name: "Paramedic aassistant" },
    { value: 4, name: "Paramedic" },
    { value: 5, name: "First responder" },
    { value: 6, name: "Firefighter" },
    { value: 7, name: "Police" },
    { value: 8, name: "THW" },
    { value: 9, name: "Geriatric nurse" },
    { value: 10, name: "Company first responder" }
];
