import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { NavComponent } from './nav/nav.component';
import { HeaderLayoutComponent } from './layout/header-layout.component';
import { LoginComponent } from './login/login.component';
import { ForgotPasswordComponent } from './login/forgot-password.component';
import { PasswordResetComponent } from './login/password-reset.component';

import { EqualValidator } from './login/equal-validator.directive';

@NgModule({
    imports: [
        CommonModule, // we use ngFor
        RouterModule,
        FormsModule
    ],
    exports: [
        NavComponent,
        HeaderLayoutComponent,
        LoginComponent,
        ForgotPasswordComponent,
        PasswordResetComponent
    ],
    declarations: [
        NavComponent,
        HeaderLayoutComponent,
        LoginComponent,
        ForgotPasswordComponent,
        PasswordResetComponent,
        EqualValidator
    ]
})

export class CoreModule { }