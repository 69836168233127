<div class='container'>
    <div class="row">
        <div class="col-12">
            <h1>Deletion Requests</h1>
        </div>
    </div>
    <div class="row">
        <div class="col-12">

            <ngx-datatable class="material striped" [rows]="data" [columns]="columns" [columnMode]="'force'"
                [headerHeight]="50" [footerHeight]="50" [rowHeight]="'auto'" [loadingIndicator]="loading">
            </ngx-datatable>

            <ng-template #editItemTmpl let-row="row" let-value="value">
                <a [routerLink]="['/deletion/' + row.id]">
                    <fa-icon [icon]="faEdit"></fa-icon>
                </a>
            </ng-template>

            <ng-template #submittedItemTmpl let-row="row" let-value="value">
                <span title="{{row.submitted | date : 'shortDate'}}">
                    {{row.submitted | date : 'dd MMM, yyyy'}}
                </span>
            </ng-template>

        </div>
    </div>
</div>