import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/index';
import { FirstresponderRequest } from '../../models/firstresponder-request.model';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-firstresponder-request-list',
  styleUrls: ['../defis/defi-list.component.scss'],
  templateUrl: './firstresponder-request-list.component.html',
})
export class FirstresponderRequestListComponent implements OnInit {
  // font awesome
  public faEdit = faEdit;

  // loading indicator
  loading = false;

  @ViewChild('editItemTmpl', { static: true }) editItemTmpl: TemplateRef<any>;

  // column definition
  columns;

  data: Array<FirstresponderRequest>;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.columns = [
      { prop: 'user.name', name: 'Name', width: 200 },
      { prop: 'user.email', name: 'Email', width: 200 },
      { prop: 'countryDisplayName', name: 'Country', width: 200 },
      {
        prop: 'qualificationTypeDisplayName',
        name: 'Qualification',
        width: 200,
      },
      { name: '', cellTemplate: this.editItemTmpl, width: 20 },
    ];

    // indicate loading
    this.loading = true;

    // load data
    this.loadData();
  }

  private loadData() {
    // load data (take, skip)
    this.api.getFirstresponderRequests().subscribe((response) => {
      // store the data
      this.data = new Array<FirstresponderRequest>();
      for (const entry of response) {
        this.data.push(new FirstresponderRequest().deserialize(entry));
      }

      // loading finished
      this.loading = false;
    });
  }
}
