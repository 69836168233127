<div class="container change">
    <div class="row">
        <div class="col-12">
            <h1>Change Request - Defibrillator details</h1>
        </div>
    </div>
    <form (ngSubmit)="onSubmit()" #defiForm="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="type">Place category</label>
                    <select class="form-control" id="type" required [(ngModel)]="model.place.type" name="type">
                        <option *ngFor="let cat of categories" [value]="cat.id">{{cat.name}}</option>
                    </select>
                    <p *ngIf="newDefi.place.typeName != model.place.typeName">
                        {{newDefi.place.typeName}}
                        <a class="" (click)="model.place.type= newDefi.place.type" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="name">Name</label>
                    <input [readOnly]="model.place.type==11" type="text" class="form-control" id="name"
                        [required]="model.place.type!=11" [(ngModel)]="(model.place.type==11) ? '' : model.place.name"
                        name="name">
                    <p *ngIf="newDefi.place.name != model.place.name">{{newDefi.place.name}}
                        <a class="" (click)="model.place.name = newDefi.place.name" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="street">Street</label>
                    <input type="text" class="form-control" id="street" required
                        [(ngModel)]="model.place.address.street" name="street">
                    <p *ngIf="newDefi.place.address.street != model.place.address.street">
                        {{newDefi.place.address.street}}
                        <a class="" (click)="model.place.address.street = newDefi.place.address.street"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="street2">Street 2</label>
                    <input type="text" class="form-control" id="street2" [(ngModel)]="model.place.address.street2"
                        name="street2">
                    <p *ngIf="newDefi.place.address.street2 != model.place.address.street2">
                        {{newDefi.place.address.street2}}
                        <a class="" (click)="model.place.address.street2 = newDefi.place.address.street2"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="zipCode"><span
                            *ngIf="newDefi.place.address.zipCode != model.place.address.zipCode"></span>Zip code</label>
                    <input type="text" class="form-control" id="zipCode" required
                        [(ngModel)]="model.place.address.zipCode" name="zipCode">
                    <p *ngIf="newDefi.place.address.zipCode != model.place.address.zipCode">
                        {{newDefi.place.address.zipCode}}
                        <a class="" (click)="model.place.address.zipCode = newDefi.place.address.zipCode"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="city">City</label>
                    <input type="text" class="form-control" id="city" required [(ngModel)]="model.place.address.city"
                        name="city">
                    <p *ngIf="newDefi.place.address.city != model.place.address.city">
                        {{newDefi.place.address.city}}
                        <a class="" (click)="model.place.address.city = newDefi.place.address.city"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="locationDescription">Floor/Room/Place name</label>
                    <input type="text" id="locationDescription" class="form-control"
                        [(ngModel)]="model.locationDescription" name="locationDescription">
                    <p *ngIf="newDefi.locationDescription != model.locationDescription">
                        {{newDefi.locationDescription}}
                        <a class="" (click)="model.locationDescription = newDefi.locationDescription"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="state">State</label>
                    <input type="text" class="form-control" id="state" [(ngModel)]="model.place.address.state"
                        name="state">
                    <p *ngIf="newDefi.place.address.state != model.place.address.state">
                        {{newDefi.place.address.state}}
                        <a class="" (click)="model.place.address.state = newDefi.place.address.state"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="country">Country</label>
                    <select class="form-control" [(ngModel)]="model.place.address.country" id="country" name="country"
                        required>
                        <option *ngFor="let c of countries" [value]="c.value">{{c.name}}</option>
                    </select>
                    <p *ngIf="newDefi.place.address.country != model.place.address.country">
                        {{newDefi.place.address.country}}
                        <a class="" (click)="model.place.address.country = newDefi.place.address.country"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="description">Place description (max. 200 characters)</label>
                    <textarea class="form-control" id="description" [(ngModel)]="model.place.description"
                        name="description" maxlength="200" rows="3"></textarea>
                    <p *ngIf="newDefi.place.description != model.place.description">
                        {{newDefi.place.description}}
                        <a class="" (click)="model.place.description = newDefi.place.description" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group check">
                    <label for="alwaysOpen">Always open (24h)</label>
                    <span>
                        <input type="checkbox" class="form-control move-left" id="alwaysOpen"
                            [(ngModel)]="model.alwaysOpen" name="alwaysOpen">
                        Please activate if the defibrillator is accessible at any time
                    </span>
                    <p *ngIf="newDefi.alwaysOpen != model.alwaysOpen">
                        {{newDefi.alwaysOpen}}
                        <a class="" (click)="model.alwaysOpen = newDefi.alwaysOpen" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group check">
                    <label>Location owner</label>
                    <br />
                    <span>
                        <select class="form-control" [(ngModel)]="model.syncPartner" id="syncPartner"
                            name="syncPartner">
                            <option *ngFor="let p of myPartners" [value]="p">{{p}}</option>
                        </select>
                    </span>
                    <span *ngIf="model.syncPartnerId">
                        <br />
                        ID: {{ model.syncPartnerId }}
                    </span>
                </div>
                <div class="form-group check">
                    <label for="alwaysOpen">Location published</label>
                    <span>
                        <input type="checkbox" class="form-control move-left" id="published"
                            [(ngModel)]="model.published" name="published">
                        Is this defibrillator shown on the map?
                    </span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label for="contactTitle">Contact title</label>
                    <input type="text" class="form-control" id="contactTitle" [(ngModel)]="model.place.contactTitle"
                        name="contactTitle">
                    <p *ngIf="newDefi.place.contactTitle != model.place.contactTitle">
                        {{newDefi.place.contactTitle}}
                        <a class="" (click)="model.place.contactTitle = newDefi.place.contactTitle"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="contactFirstName">Contact first name</label>
                    <input type="text" class="form-control" id="contactFirstName" [required]="model.place.type==11"
                        [(ngModel)]="model.place.contactFirstName" name="contactFirstName">
                    <p *ngIf="newDefi.place.contactFirstName != model.place.contactFirstName">
                        {{newDefi.place.contactFirstName}}
                        <a class="" (click)="model.place.contactFirstName = newDefi.place.contactFirstName"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="contactLastName">Contact last name</label>
                    <input type="text" class="form-control" id="contactLastName" [required]="model.place.type==11"
                        [(ngModel)]="model.place.contactLastName" name="contactLastName">
                    <p *ngIf="newDefi.place.contactLastName != model.place.contactLastName">
                        {{newDefi.place.contactLastName}}
                        <a class="" (click)="model.place.contactLastName = newDefi.place.contactLastName"
                            data-toggle="tooltip" title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="phone1">Phone 1</label>
                    <input type="text" class="form-control" id="phone1" [(ngModel)]="model.place.phone1" name="phone1">
                    <p *ngIf="newDefi.place.phone1 != model.place.phone1">
                        {{newDefi.place.phone1}}
                        <a class="" (click)="model.place.phone1 = newDefi.place.phone1" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="phone2">Phone 2</label>
                    <input type="text" class="form-control" id="phone2" [(ngModel)]="model.place.phone2" name="phone2">
                    <p *ngIf="newDefi.place.phone2 != model.place.phone2">
                        {{newDefi.place.phone2}}
                        <a class="" (click)="model.place.phone2 = newDefi.place.phone2" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="mobile">Mobile</label>
                    <input type="text" class="form-control" id="mobile" [(ngModel)]="model.place.mobile" name="mobile">
                    <p *ngIf="newDefi.place.mobile != model.place.mobile">
                        {{newDefi.place.mobile}}
                        <a class="" (click)="model.place.mobile = newDefi.place.mobile" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="fax">Fax</label>
                    <input type="text" class="form-control" id="fax" [(ngModel)]="model.place.fax" name="fax">
                    <p *ngIf="newDefi.place.fax != model.place.fax">
                        {{newDefi.place.fax}}
                        <a class="" (click)="model.place.fax = newDefi.place.fax" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" [(ngModel)]="model.place.email" name="email">
                    <p *ngIf="newDefi.place.email != model.place.email">
                        {{newDefi.place.email}}
                        <a class="" (click)="model.place.email = newDefi.place.email" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <div class="form-group">
                    <label for="website">Website</label>
                    <input type="text" class="form-control" id="website" [(ngModel)]="model.place.website"
                        name="website">
                    <p *ngIf="newDefi.place.website != model.place.website">
                        {{newDefi.place.website}}
                        <a class="" (click)="model.place.website = newDefi.place.website" data-toggle="tooltip"
                            title="Accept new value!">
                            <fa-icon [icon]="faCheckCircle" aria-hidden="true"></fa-icon>
                        </a>
                    </p>
                </div>
                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [disableDefaultUI]="false">
                    <agm-marker *ngFor="let m of markers; let i = index" [latitude]="m.lat" [longitude]="m.lng"
                        [label]="m.label" [iconUrl]="m.icon" [markerDraggable]="m.draggable">
                    </agm-marker>
                </agm-map>
            </div>
        </div>
        <div class="row">
            <div class="col-12 text-right" style="margin-top: 20px;">
                <button type="button" class="btn btn-secondary" [routerLink]="['/change']">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="ignoreRequest()"
                    ng-really-message="Do you really want to ignore this request?" [disabled]="this.submitted">Decline
                    request</button>
                <button type="submit" class="btn btn-success" [disabled]="this.submitted"
                    ng-really-message="Do you really want to delete this defibrillator?">Accept request</button>
            </div>
        </div>
    </form>
</div>