import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Component, ViewChild, Input } from '@angular/core';

@Component({
    selector: 'ngbd-modal-content',
    template: `
    <div class="modal-header">
      <h4 class="modal-title">Delete User</h4>
      <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
        Do you really want to delete the user?
    </div>
    <div class="modal-footer">
      <button class="btn btn-danger pull-right" (click)="delete()" [style.display]="'inherit'" ng-really-message="Are you sure?">Delete User</button>
        <button class="btn btn-outline-dark pull-right" (click)="activeModal.dismiss()" [style.display]="'inherit'" ng-really-message="Are you sure?">Cancel</button>
    </div>
  `
})
export class NgModalConfirm {
    @Input() owner: any;
    yes;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {

    }

    delete() {
        this.activeModal.close(this.yes);
    }

    close() {
        this.yes = true;
        const aaa = this.activeModal.close(this.yes);
        console.log(this.yes);
    }


}
