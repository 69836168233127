import { Countries } from './defi.model';

interface Serializable<T> {
  deserialize(input: Object): T;
}

export class User implements Serializable<User> {
  public id: string;
  public firstName: string;
  public middleName: string;
  public lastName: string;
  public email: string;
  public emailConfirmed: boolean;
  public phoneNumber: string;
  public firstAider: boolean;
  public roles: Array<string>;
  public adminCountries: Array<string>;
  public adminPartners: Array<string>;

  // auto property
  get name(): string {
    return this.firstName + ' ' + this.lastName;
  }

  get adminCountriesDisplayName(): Array<string> {
    if (!this.adminCountries) return [];
    let result = new Array<string>();
    this.adminCountries.forEach((s) => {
      let country = Countries.find(
        (x) => x.value.toLowerCase() == s.toLowerCase()
      );
      if (!country) result.push(s);
      else result.push(country.name);
    });
    return result;
  }

  get roleSuperAdmin(): boolean {
    if (this.roles.indexOf('SuperAdmin') > -1) return true;
    return false;
  }

  set roleSuperAdmin(value: boolean) {
    if (value) {
      // add if not exists
      if (!this.roleSuperAdmin) this.roles.push('SuperAdmin');
    } else {
      // remove if exists
      if (this.roleSuperAdmin) {
        let index: number = this.roles.indexOf('SuperAdmin');
        if (index !== -1) {
          this.roles.splice(index, 1);
        }
      }
    }
  }

  get roleCountryAdmin(): boolean {
    if (this.roles.indexOf('CountryAdmin') > -1) return true;
    return false;
  }

  set roleCountryAdmin(value: boolean) {
    if (value) {
      // add if not exists
      if (!this.roleCountryAdmin) this.roles.push('CountryAdmin');
    } else {
      // remove if exists
      if (this.roleCountryAdmin) {
        let index: number = this.roles.indexOf('CountryAdmin');
        if (index !== -1) {
          this.roles.splice(index, 1);
        }
      }
    }
  }

  get rolePartnerAdmin(): boolean {
    if (this.roles.indexOf('PartnerAdmin') > -1) return true;
    return false;
  }

  set rolePartnerAdmin(value: boolean) {
    if (value) {
      // add if not exists
      if (!this.rolePartnerAdmin) this.roles.push('PartnerAdmin');
    } else {
      // remove if exists
      if (this.rolePartnerAdmin) {
        let index: number = this.roles.indexOf('PartnerAdmin');
        if (index !== -1) {
          this.roles.splice(index, 1);
        }
      }
    }
  }

  constructor() {
    this.roles = new Array<string>();
    this.adminCountries = new Array<string>();
    this.adminPartners = new Array<string>();
  }

  public static Partners = [
    'Cisali',
    'MedX5',
    'SingaporePublishedDefis',
    'MedX5Test',
    'AugsburgVerwaltung',
    'LagSh',
    'Stuckenberg',
  ];

  deserialize(input) {
    this.id = input.id;
    this.firstName = input.firstName;
    this.middleName = input.middleName;
    this.lastName = input.lastName;
    this.email = input.email;
    this.emailConfirmed = input.emailConfirmed;
    this.phoneNumber = input.phoneNumber;
    this.firstAider = input.firstAider;
    this.roles = input.roles;
    this.adminCountries = input.adminCountries;
    this.adminPartners = input.adminPartners;

    return this;
  }
}
