import { Observable } from 'rxjs';
import { Inject } from '@angular/core';
export class Utils {
  constructor() {}
}

export function removeFromList(item, list: any[]) {
  const i = list.indexOf(item);
  if (i >= 0) {
    list.splice(i, 1);
  }
}

export function readUrl(file) {
  const reader = new FileReader();
  const o = Observable.create((observer: any) => {
    reader.onload = function (e: any) {
      const data = e.target;
      const src = data.result;
      observer.next({ file, src });
      observer.complete();
    };
  });
  reader.readAsDataURL(file);
  return o;
}

export function getParams(path?) {
  const qd = {};
  if (path) {
    path
      .substr(1)
      .split(`&`)
      .forEach((item) => {
        const [k, v] = item.split(`=`);
        // v = v && decodeURIComponent(v);
        qd[k] = v && decodeURIComponent(v);
      });
  }
  return qd;
}

export function loadScript(url) {
  const node = document.createElement('script');
  node.src = url;
  node.type = 'text/javascript';
  node.async = true;
  node.charset = 'utf-8';
  document.getElementsByTagName('head')[0].appendChild(node);
}

export const globals: {
  translate: any;
  window: Window;
  document: Document;
  isBrowser: boolean;
} = {
  translate: null,
  window: null,
  document: null,
  isBrowser: true
};
