import * as moment from 'moment';
import { Injectable, PLATFORM_ID, Inject, Injector } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { parse } from 'cookie';

declare let document;

let storage = {};
let isBrowser;

function getExpiry() {
  return moment()
    .add(1, 'year')
    .toString();
}

function saveCookie(key: string, value: string, expires = false) {
  let c = `${key}=${value}; path=/`;
  if (expires) {
    c = `${c}; Expires=${typeof expires === 'string' ? String(expires) : getExpiry()}`;
  } else {
    c = `${c}; Expires=${new Date(Date.now() + 1000 * 60 * 60 * 24 * 365 * 10).toUTCString()}`;
  }
  document.cookie = c;
}

function readCookie(key: string) {
  const nameEQ = `${key}=`;
  const ca = document.cookie.split(';').map(s => s.trim());
  for (let i = 0; i < ca.length; i++) {
    const c = ca[i];
    if (c.indexOf(nameEQ) === 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return null;
}

function deleteCookie(key: string) {
  document.cookie = `${key}=; path=/; Max-Age=-1; expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
}

function set(key, value, expires = false) {
  if (isBrowser) {
    saveCookie(key, JSON.stringify(value), expires);
  }
  storage[key] = value;
}

function get(key) {
  if (!storage.hasOwnProperty(key)) {
    const value = isBrowser && readCookie(key);
    try {
      if (value) {
        storage[key] = value && JSON.parse(value);
      }
    } catch (err) {
      console.error(`Failed to parse cookie ${key}`);
      return null;
    }
  }
  return storage[key];
}

function remove(key) {
  delete storage[key];
  if (isBrowser) {
    deleteCookie(key);
  }
}

function parseCookie(c) {
  try {
    const parsed: { [key: string]: string } = parse(c);
    Object.entries(parsed).forEach(([key, value]) => {
      try {
        value = JSON.parse(value);
      } catch (err) {}
      set(key, value);
    });
  } catch (error) {
    console.error('Failed to parse cookie', c);
  }
}

function clear() {
  storage = {};
}

export default {
  set,
  get,
  remove,
  parseCookie,
  clear
};

@Injectable()
export class CookieService {
  private isBrowser;
  set: (key: string, value: string | boolean, expires?: string | boolean) => void;
  get: (key: string) => any;
  remove: (key: string) => void;
  parseCookie: (cookie: string) => void;
  clear: () => void;

  constructor(@Inject(PLATFORM_ID) private platformId: Record<string, any>, private injector: Injector) {
    this.isBrowser = isBrowser = isPlatformBrowser(this.platformId);
    this.set = set;
    this.get = get;
    this.remove = remove;
    this.parseCookie = parseCookie;
    this.clear = clear;
    if (!this.isBrowser) {
      const request = this.injector.get('REQUEST');
      const c = request.headers.cookie;
      if (c && c.length) {
        this.parseCookie(c);
      }
    }
  }
}
