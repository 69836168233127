<div class="container">
    <div class="row">
        <div class="col-12">
            <h1>First responder request</h1>
        </div>
    </div>

    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="firstName">First name</label>
                    <input type="text" class="form-control" id="firstName" readonly [(ngModel)]="request.user.firstName"
                        name="firstName">
                </div>
                <div class="form-group">
                    <label for="lastName">Last name</label>
                    <input type="text" class="form-control" id="lastName" readonly [(ngModel)]="request.user.lastName"
                        name="lastName">
                </div>
                <div class="form-group">
                    <label for="email">Email</label>
                    <input type="text" class="form-control" id="email" readonly [(ngModel)]="request.user.email"
                        name="email">
                </div>
                <div class="form-group">
                    <label for="qualificationType">Qualification</label>
                    <input type="text" class="form-control" id="qualificationType" readonly
                        [(ngModel)]="request.qualificationType" name="qualificationType">
                </div>
                <div class="form-group">
                    <label for="mobilePhone">Mobile phone</label>
                    <input type="text" class="form-control" id="mobilePhone" readonly [(ngModel)]="request.mobilePhone"
                        name="mobilePhone">
                </div>
                <div class="form-group">
                    <label for="submitted">Request submitted</label>
                    <input type="text" class="form-control" id="submitted" readonly [(ngModel)]="request.submitted"
                        name="submitted">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-12 text-right" style="margin-top: 20px;">
                <button type="button" class="btn btn-secondary"
                    [routerLink]="['/firstresponderrequest']">Cancel</button>
                <button type="button" class="btn btn-danger" (click)="ignoreRequest()"
                    ng-really-message="Do you really want to ignore this request?" [disabled]="this.submitted">Ignore
                    request</button>
                <button type="submit" class="btn btn-success" [disabled]="this.submitted"
                    ng-really-message="Do you really want to delete this defibrillator?">Accept request</button>
            </div>
        </div>
    </form>
</div>