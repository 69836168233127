interface Serializable<T> {
  deserialize(input: Object): T;
}

export class GooglePlace implements Serializable<GooglePlace> {
  public placeId: string;

  public cisaliPlaceType: number;

  public placeName: string;
  public phoneNumber: string;
  public openingHours: Array<string>;

  public route: string;
  public streetNumber: string;
  public zipCode: string;
  public city: string;
  public state: string;
  public country: string;

  public latitude: number;
  public longitude: number;

  // auto property
  get street(): string {
    return (
      this.route + (this.streetNumber != null ? ' ' + this.streetNumber : '')
    );
  }

  constructor() {
    this.openingHours = new Array<string>();
  }

  deserialize(input) {
    this.placeId = input.placeId;

    this.cisaliPlaceType = input.cisaliPlaceType;

    this.placeName = input.placeName;
    this.phoneNumber = input.phoneNumber;
    this.openingHours = input.openingHours;

    this.route = input.route;
    this.streetNumber = input.streetNumber;
    this.zipCode = input.zipCode;
    this.city = input.city;
    this.state = input.state;
    this.country = input.country;

    this.latitude = input.latitude;
    this.longitude = input.longitude;

    return this;
  }
}
