<div class="container">
    <div class="row">
        <div class="col-3">
            <a [routerLink]="['']">
                <img href="/assets/logo.svg" src="/assets/logo.png" width="200px" />
            </a>
        </div>
        <div class="col-9 text-right">
            <p class="navbar-text">
                <span class="username" hide-sm>{{authService.loginData?.firstName}}
                    {{authService.loginData?.lastName}}</span>
            </p>
            <button type="button" class="btn btn-info" style="margin: 10px;" (click)="logout()"
                *ngIf="authService.isLoggedIn()">Logout</button>
        </div>
    </div>
</div>

<div>
    <app-toh-nav></app-toh-nav>
</div>