<div class='container'>
    <div class="row">
        <div class="col-md-12">
            <h1>Defibrillators</h1>
        </div>
        <div class="col-md-6">
            <div class="input-group">
                <span class="input-group-text" id="basic-addon1">
                    <fa-icon [icon]="faSearch"></fa-icon>
                </span>
                <input type='text' class="form-control" placeholder='Search defibrillator'
                    (keyup)='filterUpdated($event)' />
            </div>
        </div>
        <div class="col-md-6 text-right">
            <a [routerLink]="['/defibrillator/new']" class="btn btn-success pull-right">+ New Defibrillator</a>
        </div>
    </div>
    <ngx-datatable class="material striped" [rows]="data" [columns]="columns" [columnMode]="'force'" [headerHeight]="50"
        [footerHeight]="50" [rowHeight]="'auto'" [loadingIndicator]="loading" [externalPaging]="true"
        [count]="totalElements" [offset]="pageNumber" [limit]="size" (page)='setPage($event)' [externalSorting]="true"
        (sort)="onSort($event)">
    </ngx-datatable>

    <ng-template #companyNameItemTmpl let-row="row" let-value="value">
        <span title="{{row.displayName}}">
            {{row.displayName}}
        </span>
    </ng-template>

    <ng-template #placeAlwaysOpenTmpl let-row="row" let-value="value">
        <input type="checkbox" [checked]="value" onclick="return false;" />
    </ng-template>

    <ng-template #editItemTmpl let-row="row" let-value="value">
        <a [routerLink]="['/defibrillator/' + row.id]">
            <fa-icon [icon]="faEdit"></fa-icon>
        </a>
    </ng-template>

    <div class="row" style="margin-top: 50px;" *ngIf="auth.isSuperAdmin">
        <div class="col-md-12">
            <h1>Export</h1>
        </div>
        <div class="col-md-3">
            <div class="input-group">
                <select class="form-control" [(ngModel)]="exportFilterCountryCode" id="exportFilterCountryCode"
                    name="exportFilterCountryCode" required>
                    <option *ngFor="let c of countries" [value]="c.value">{{c.name}}</option>
                </select>
            </div>
        </div>
        <div class="col-md-3">
            <div class="input-group">
                <input [(ngModel)]="exportFilterZip" type='text' class="form-control"
                    placeholder='ZIP filter (min 2 digits)' />
            </div>
        </div>
    </div>
    <div class="row" *ngIf="auth.isSuperAdmin">
        <div class="col-md-12">
            <button type="button" class="btn btn-success pull-left" (click)="excelExport()">Download Excel</button>
        </div>
    </div>
</div>