import { Defi } from "./defi.model"

interface Serializable<T> {
    deserialize(input: Object): T;
}

export class DeleteRequest implements Serializable<DeleteRequest> {
    public id: string;
    public requestId: number;
    public submitted: Date;
    public ipAddress: string;
    public deleteReason: string;

    public defi: Defi;

    constructor() {
        this.defi = new Defi();
    }

    deserialize(input) {
        this.id = input.id;
        this.requestId = input.requestId;
        this.submitted = input.submitted;
        this.ipAddress = input.ipAddress;
        this.deleteReason = input.deleteReason;

        this.defi = new Defi().deserialize(input.defibrillator);

        return this;
    }
}
