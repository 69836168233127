import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ApiService } from '../../services/index';
import { ImageRequest } from '../../models/image-request.model';
import { faEdit } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-image-request-list',
  styleUrls: ['../defis/defi-list.component.scss'],
  templateUrl: './image-request-list.component.html',
})
export class ImageRequestListComponent implements OnInit {
  // font awesome
  public faEdit = faEdit;

  // loading indicator
  loading = false;

  @ViewChild('submittedItemTmpl', { static: true })
  submittedItemTmpl: TemplateRef<any>;
  @ViewChild('editItemTmpl', { static: true }) editItemTmpl: TemplateRef<any>;

  // column definition
  columns;

  data: Array<ImageRequest>;

  constructor(private api: ApiService) {}

  ngOnInit() {
    this.columns = [
      { prop: 'requestId', name: 'Id', width: 10 },
      {
        prop: 'submitted',
        name: 'Submitted',
        cellTemplate: this.submittedItemTmpl,
        width: 70,
      },
      { prop: 'defi.place.typeName', name: 'Category', width: 80 },
      { prop: 'defi.name', name: 'Name', width: 700 },
      { name: 'Actions', cellTemplate: this.editItemTmpl, width: 20 },
    ];

    // indicate loading
    this.loading = true;

    // load data
    this.loadData();
  }

  private loadData() {
    // load data (take, skip)
    this.api.getDefiImageRequests().subscribe((response) => {
      // store the data
      this.data = new Array<ImageRequest>();
      for (const entry of response) {
        this.data.push(new ImageRequest().deserialize(entry));
      }

      // loading finished
      this.loading = false;
    });
  }
}
