import { Injectable } from '@angular/core';
import { Defi } from '../models/defi.model';
import { ChangeRequest } from '../models/change-request.model';
import { DeleteRequest } from '../models/delete-request.model';
import { RegistrationRequest } from '../models/registration-request.model';
import { ImageRequest } from '../models/image-request.model';
import { User } from '../models/user.model';
import { FirstresponderRequest } from '../models/firstresponder-request.model';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable()
export class ApiService {
  get(url, config?) {
    return this.http.get<any>(url, config);
  }

  getDefi(id: string) {
    return this.http.get<any>('/api/defiadmin/' + id);
  }

  postDefi(defi: Defi) {
    return this.http.post<any>('/api/defiadmin', defi);
  }

  postDefiImage(file: File) {
    const postData: any = { fileType: 1 };
    const formData: FormData = new FormData();
    formData.append('files', file, file.name);
    const headers = new HttpHeaders();
    headers.append('FileUploadLeaveContentTypeBlank', '1');

    if (postData !== '' && postData !== undefined && postData !== null) {
      for (const property in postData) {
        if (postData.hasOwnProperty(property)) {
          formData.append(property, postData[property]);
        }
      }
    }

    return this.http.post<any>('/api/defiadmin/image', formData, { headers });
  }

  putDefi(defi: Defi) {
    return this.http.put<any>('/api/defiadmin/' + defi.id, defi);
  }

  deleteDefi(defi: Defi) {
    return this.http.delete<any>('/api/defiadmin/' + defi.id);
  }

  testLocation(defi: Defi) {
    return this.http.post<any>('/api/defiadmin/testlocation', defi);
  }

  getMyCountries() {
    return this.http.get<any>('/api/defiadmin/mycountries');
  }

  getMyPartners() {
    return this.http.get<any>('/api/defiadmin/mypartners');
  }

  getDefis() {
    return this.http.get<any>(`/api/defiadmin`);
  }

  getDefisPagedSortedFiltered(
    take: number,
    skip: number,
    sort: string,
    direction: string,
    filter: string
  ) {
    return this.http.get<any>(
      '/api/defiadmin/' +
        take.toString() +
        '/' +
        skip.toString() +
        '?sort=' +
        sort +
        '&direction=' +
        direction +
        '&filter=' +
        filter
    );
  }

  getDefisExportExcel(countryCodeFilter: string, zipFilter: string) {
    return this.http.get(
      '/api/defiadmin/export/excel?countryCodeFilter=' +
        countryCodeFilter +
        '&zipFilter=' +
        zipFilter,
        { observe: 'response', responseType: 'blob' }
    );
  }

  getUsers() {
    return this.http.get<any>(`/api/user`);
  }

  getAdmins() {
    return this.http.get<any>(`/api/useradmin/admin`);
  }

  getAdmin(id: string) {
    return this.http.get<any>(`/api/useradmin/admin/` + id);
  }

  postAdmin(user: User) {
    return this.http.post<any>('/api/useradmin/admin', user);
  }

  putAdmin(user: User) {
    return this.http.put<any>('/api/useradmin/admin/' + user.id, user);
  }

  deleteAdmin(user: User) {
    return this.http.delete<any>('/api/useradmin/admin/' + user.id);
  }

  getRegisteredUser(email: string) {
    return this.http.get<any>(`/api/useradmin/byemail?email=${email}`);
  }

  postRegisteredUser(user: User) {
    return this.http.post<any>('/api/useradmin/', user);
  }

  putRegisteredUser(user: User) {
    return this.http.put<any>('/api/useradmin/' + user.id, user);
  }

  getFirstresponders() {
    return this.http.get<any>(`/api/useradmin/firstresponder`);
  }

  getFirstresponder(id: string) {
    return this.get(`/api/useradmin/firstresponder/` + id);
  }

  postFirstresponder(user: User) {
    return this.http.post('/api/useradmin/firstresponder', user);
  }

  putFirstresponder(user: User) {
    return this.http.put('/api/useradmin/firstresponder/' + user.id, user);
  }

  deleteFirstresponder(user: User) {
    return this.http.delete('/api/useradmin/firstresponder/' + user.id);
  }

  getFirstresponderRequests() {
    return this.http.get<any>(`/api/firstresponderrequest`);
  }

  getFirstresponderRequest(id: string) {
    return this.http.get<any>(`/api/firstresponderrequest/` + id);
  }

  postFirstresponderRequest(request: FirstresponderRequest) {
    return this.http.post<any>(
      '/api/firstresponderrequest/' + request.id,
      null
    );
  }

  deleteFirstresponderRequest(request: FirstresponderRequest) {
    return this.http.delete<any>('/api/firstresponderrequest/' + request.id);
  }

  getDefiChangeRequests() {
    return this.http.get<any>('/api/changerequest');
  }

  getDefiChangeRequest(id: string) {
    return this.http.get<any>('/api/changerequest/' + id);
  }

  postDefiChangeRequest(request: ChangeRequest, defi: Defi) {
    return this.http.post<any>('/api/changerequest/' + request.id, defi);
  }

  deleteDefiChangeRequest(request: ChangeRequest) {
    return this.http.delete<any>('/api/changerequest/' + request.id);
  }

  getDefiDeleteRequests() {
    return this.http.get<any>('/api/deleterequest');
  }

  getDefiDeleteRequest(id: string) {
    return this.http.get<any>('/api/deleterequest/' + id);
  }

  postDefiDeleteRequest(request: DeleteRequest) {
    return this.http.post<any>('/api/deleterequest/' + request.id, '');
  }

  deleteDefiDeleteRequest(request: DeleteRequest) {
    return this.http.delete<any>('/api/deleterequest/' + request.id);
  }

  getDefiRegistrationRequests() {
    return this.http.get<any>('/api/registrationrequest');
  }

  getDefiRegistrationRequest(id: string) {
    return this.http.get<any>('/api/registrationrequest/' + id);
  }

  postDefiRegistrationRequest(request: RegistrationRequest, defi: Defi) {
    return this.http.post<any>('/api/registrationrequest/' + request.id, defi);
  }

  deleteDefiRegistrationRequest(request: RegistrationRequest) {
    return this.http.delete<any>('/api/registrationrequest/' + request.id);
  }

  getDefiImageRequests() {
    return this.http.get<any>('/api/imagerequest');
  }

  getDefiImageRequest(id: string) {
    return this.http.get<any>('/api/imagerequest/' + id);
  }

  getDefiImageRequestImage(request: ImageRequest) {
    return this.http.get<any>('/api/imagerequest/image/' + request.id);
  }

  postDefiImageRequest(request: ImageRequest) {
    return this.http.post<any>('/api/imagerequest/' + request.id, '');
  }

  deleteDefiImageRequest(request: ImageRequest) {
    return this.http.delete<any>('/api/imagerequest/' + request.id);
  }

  constructor(public http: HttpClient) {}
}
