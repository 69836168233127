import { Injectable } from '@angular/core';
import { ApiService } from '../../services/api';
import { AuthService } from '../../services/auth';
import { Action, Store } from '@ngrx/store';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { Observable, of } from 'rxjs';
import { withLatestFrom, map } from 'rxjs/operators';

import { ActionTypes } from '../app.actions';
import { AppState } from '../app.state';
import { LoadUserSuccessAction } from '../../state';

@Injectable()
export class ApiEffects {
  @Effect()
  loadUserSuccess$: Observable<Action> = this.actions$.pipe(
    ofType(ActionTypes.LOAD_USER_SUCCESS),
    map((action: LoadUserSuccessAction) => action.payload),
    withLatestFrom(this.store$.select('auth')),
    map(([payload, auth]: [any, any]) => {
      this.auth.loginData = { ...auth.user };
      return {
        type: ActionTypes.NULL_ACTION,
        payload: payload
      };
    })
  );

  constructor(private actions$: Actions, private api: ApiService, private auth: AuthService, private store$: Store<AppState>) {}
}
