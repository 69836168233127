import { Component, ViewChild, Input, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { Observable, EMPTY } from 'rxjs';
import { switchMap, catchError } from 'rxjs/operators';
import {
  NgbModal,
  NgbActiveModal,
  NgbModalOptions,
} from '@ng-bootstrap/ng-bootstrap';
import { ApiService, Shared } from '../../services/index';

import { Defi, Countries } from '../../models/defi.model';
import { GooglePlace } from '../../models/google-place.model';
import {
  PlaceCategory,
  PlaceCategoryList,
} from '../../models/place-category.model';

import {
  faPlusCircle,
  faCheckCircle,
  faMinusCircle,
  faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-defi-form',
  styleUrls: ['./defi-form.component.scss'],
  templateUrl: './defi-form.component.html',
})

// just an interface for type safety.
export class DefiFormComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private api: ApiService,
    private router: Router,
    private modalService: NgbModal
  ) {}

  // fontawesome
  faPlusCircle = faPlusCircle;
  faCheckCircle = faCheckCircle;
  faMinusCircle = faMinusCircle;
  faExclamationTriangle = faExclamationTriangle;

  isNewDefi = false;
  model: Defi = new Defi();
  googlePlace: GooglePlace = new GooglePlace();
  @ViewChild('fileInput', { static: true }) fileInput: any;
  lat: number;
  lng: number;
  markers;
  longitude;
  buttonActive = false;
  resetButtonActive;
  latitude;
  email;
  ownerEmail;
  ownerDeputyEmail;
  ownerInputShow = false;
  ownerDeputyInputShow = false;
  ownerExists = false;
  ownerDeputyExists = false;
  ownerPhone;
  closeResult: string;
  ownerEditable = false;
  ownerDeputyEditable = false;
  user = [];
  marker = [];
  userObject;
  userObjectHelper;

  zoom = 18;

  countries = Countries;
  selectedValue = null;

  apiUrl: string = Shared.apiUrl;

  categories: Array<PlaceCategory> = PlaceCategoryList.getCategoryList();
  myPartners;

  public dpConfig = {
    format: 'YYYY-MM-DD',
    firstDayOfWeek: 'mo',
  };

  submitted = false;

  ngOnInit() {
    this.route.params
      .pipe(
        switchMap((params: Params) =>
          params.id == 'new'
            ? Observable.throw(null)
            : this.api.getDefi(params.id)
        )
      )
      .pipe(
        catchError((e) => {
          // new defi
          this.isNewDefi = true;
          this.model = new Defi();
          this.loadPartners();
          return EMPTY;
        })
      )
      .subscribe((defi) => {
        // existing defi from service
        this.isNewDefi = false;
        this.model = new Defi().deserialize(defi);
        this.loadPartners();

        this.userObject = this.model.owner;
        if (this.model.owner.email) {
          this.ownerExists = true;

          if (this.model.owner.emailConfirmed == false) {
            this.ownerEditable = true;
          }
        }

        if (this.model.ownerDeputy.email) {
          this.ownerDeputyExists = true;

          if (this.model.owner.emailConfirmed == false) {
            this.ownerEditable = true;
          }
        }

        // marker data
        this.lat = this.model.place.address.location.latitude;
        this.lng = this.model.place.address.location.longitude;

        if (this.model.location.latitude) {
          this.latitude = this.model.location.latitude;
          this.longitude = this.model.location.longitude;
        } else {
          this.latitude = this.lat;
          this.longitude = this.lng;
        }
        this.loadMarkers();
        if (this.lat != this.latitude || this.lng != this.longitude) {
          this.resetButtonActive = true;
        }
      });
  }

  toggleButton() {
    if (this.lat == this.latitude && this.lng == this.longitude) {
      this.buttonActive = false;
      this.resetButtonActive = false;
    } else {
      this.buttonActive = true;
      this.resetButtonActive = true;
    }
  }

  loadMarkers() {
    this.markers = this.marker = [
      {
        lat: this.googlePlace.placeId ? this.googlePlace.latitude : this.lat,
        lng: this.googlePlace.placeId ? this.googlePlace.longitude : this.lng,
        icon: '/assets/home.png',
        draggable: false,
      },
      {
        lat: this.latitude ? this.latitude : this.lat,
        lng: this.longitude ? this.longitude : this.lng,
        icon: '/assets/defi.png',
        draggable: true,
      },
    ];
  }

  markerDragEnd(m: marker, event: google.maps.MouseEvent) {
    // convert to get coords
    const eventStr = JSON.stringify(event);
    const coords = JSON.parse(eventStr);
    // get corrds
    this.latitude = coords.latLng.lat;
    this.longitude = coords.latLng.lng;
    this.buttonActive = false;
    this.resetButtonActive = true;
  }

  checkOwnerMail() {
    if (this.ownerEmail) {
      this.api.getRegisteredUser(this.ownerEmail).subscribe((response) => {
        this.model.owner = response;
        if (response.message) {
          this.ownerExists = false;
          const ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
          };

          const modalRef = this.modalService.open(
            NgbdModalContent,
            ngbModalOptions
          );
          modalRef.componentInstance.userMail = this.ownerEmail;
          modalRef.result.then(
            (result) => {
              this.ownerInputShow = false;
              this.ownerExists = true;
              this.model.owner = result;
            },
            (aaa) => {
              this.ownerInputShow = false;
            }
          );
        } else {
          this.ownerInputShow = false;
          this.ownerExists = true;
        }
        if (response.emailConfirmed == false) {
          this.ownerEditable = true;
        } else {
          this.ownerEditable = false;
        }
      });
    }
  }

  editOwner() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(NgbdModalContent, ngbModalOptions);

    modalRef.componentInstance.user = this.model.owner;
    modalRef.result.then(
      (result) => {
        this.closeResult = result;
        this.ownerExists = true;
        this.model.owner = result;
      },
      (aaa) => {
        this.ownerInputShow = false;
      }
    );
  }

  clearOwner() {
    this.model.owner = null;
    this.ownerEmail = '';
    this.ownerExists = false;
  }

  checkOwnerDeputyMail() {
    if (this.ownerDeputyEmail) {
      this.api
        .getRegisteredUser(this.ownerDeputyEmail)
        .subscribe((response) => {
          this.model.ownerDeputy = response;
          if (response.message) {
            this.ownerDeputyExists = false;

            const ngbModalOptions: NgbModalOptions = {
              backdrop: 'static',
              keyboard: false,
            };
            const modalRef = this.modalService.open(
              NgbdModalContent,
              ngbModalOptions
            );
            modalRef.componentInstance.userDeputyMail = this.ownerDeputyEmail;
            modalRef.result.then(
              (result) => {
                this.ownerDeputyInputShow = false;
                this.ownerDeputyExists = true;
                this.model.ownerDeputy = result;
              },
              (aaa) => {
                this.ownerDeputyInputShow = false;
              }
            );
          } else {
            this.ownerDeputyInputShow = false;
            this.ownerDeputyExists = true;
          }

          if (response.emailConfirmed == false) {
            this.ownerDeputyEditable = true;
          } else {
            this.ownerDeputyEditable = false;
          }
        });
    }
  }

  editOwnerDeputy() {
    const ngbModalOptions: NgbModalOptions = {
      backdrop: 'static',
      keyboard: false,
    };
    const modalRef = this.modalService.open(NgbdModalContent, ngbModalOptions);
    modalRef.componentInstance.user = this.model.ownerDeputy;
    modalRef.result.then(
      (result) => {
        this.closeResult = result;
        this.ownerDeputyExists = true;
        this.model.ownerDeputy = result;
      },
      (aaa) => {
        this.ownerDeputyInputShow = false;
      }
    );
  }

  clearOwnerDeputy() {
    this.model.ownerDeputy = null;
    this.ownerDeputyEmail = '';
    this.ownerDeputyExists = false;
  }

  setMarker() {
    this.markers.pop();
    this.markers.push({
      lat: this.latitude,
      lng: this.longitude,
      icon: '/assets/defi.png',
      draggable: true,
    });
    this.buttonActive = false;
    this.resetButtonActive = true;
  }

  setMarkerToAddress() {
    this.markers.pop();
    this.markers.push({
      lat: this.googlePlace.placeId ? this.googlePlace.latitude : this.lat,
      lng: this.googlePlace.placeId ? this.googlePlace.longitude : this.lng,
      icon: '/assets/defi.png',
      draggable: true,
    });

    this.latitude = this.googlePlace.placeId
      ? this.googlePlace.latitude
      : this.lat;
    this.longitude = this.googlePlace.placeId
      ? this.googlePlace.longitude
      : this.lng;

    this.buttonActive = false;
    this.resetButtonActive = false;
  }

  loadPartners() {
    this.api.getMyPartners().subscribe((response) => {
      this.myPartners = response;
      if (!this.model.syncPartner && this.myPartners) {
        this.model.syncPartner = this.myPartners[0];
      }

      // if no partners besides cisali are available, shrink the country list
      if (this.myPartners.length == 1 && this.myPartners[0] === 'Cisali') {
        this.api.getMyCountries().subscribe((response) => {
          this.countries = Countries.filter((item) => {
            if (response.includes(item.value)) {
              return true;
            }
            return false;
          });
          if (this.countries.length == 1) {
            this.model.place.address.country = this.countries[0].value;
          }
        });
      } else {
        this.countries = Countries;
      }
    });
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    this.submitted = true;

    // set model location on save
    if (this.lat == this.latitude && this.lng == this.longitude) {
      this.model.location = null;
    } else {
      this.model.location.latitude = this.latitude;
      this.model.location.longitude = this.longitude;
    }

    // remove the timezone from the defi date fields
    if ((this.model.purchasedOn as any) instanceof Object) {
      this.model.purchasedOn = (this.model.purchasedOn as any).format(
        'YYYY-MM-DD'
      );
    }
    if ((this.model.initialOperationOn as any) instanceof Object) {
      this.model.initialOperationOn = (this.model
        .initialOperationOn as any).format('YYYY-MM-DD');
    }
    if ((this.model.nextInspection as any) instanceof Object) {
      this.model.nextInspection = (this.model.nextInspection as any).format(
        'YYYY-MM-DD'
      );
    }
    // remove the timezone from the pad expiration
    if ((this.model.pad1Expiration as any) instanceof Object) {
      this.model.pad1Expiration = (this.model.pad1Expiration as any).format(
        'YYYY-MM-DD'
      );
    }
    if ((this.model.pad1ExpirationReminder as any) instanceof Object) {
      this.model.pad1ExpirationReminder = (this.model
        .pad1ExpirationReminder as any).format('YYYY-MM-DD');
    }
    if ((this.model.pad2Expiration as any) instanceof Object) {
      this.model.pad2Expiration = (this.model.pad2Expiration as any).format(
        'YYYY-MM-DD'
      );
    }
    if ((this.model.pad2ExpirationReminder as any) instanceof Object) {
      this.model.pad2ExpirationReminder = (this.model
        .pad2ExpirationReminder as any).format('YYYY-MM-DD');
    }
    if ((this.model.batteryExpiration as any) instanceof Object) {
      this.model.batteryExpiration = (this.model
        .batteryExpiration as any).format('YYYY-MM-DD');
    }
    if ((this.model.batteryExpirationReminder as any) instanceof Object) {
      this.model.batteryExpirationReminder = (this.model
        .batteryExpirationReminder as any).format('YYYY-MM-DD');
    }

    // send changed to API and redirect back
    this.model.confirmed = true;
    if (this.isNewDefi) {
      // post new defi
      this.api.postDefi(this.model).subscribe((response) => {
        this.router.navigate(['defibrillator']);
      });
    } else {
      // put existing defi
      this.api.putDefi(this.model).subscribe((response) => {
        this.router.navigate(['defibrillator']);
      });
    }
  }

  deleteDefi() {
    if (!this.isNewDefi) {
      // set the form to submitted to avoid double-firing
      this.submitted = true;

      // delete existing defi
      this.api.deleteDefi(this.model).subscribe((response) => {
        this.router.navigate(['defibrillator']);
      });
    }
  }

  testLocation() {
    // load location
    this.api.testLocation(this.model).subscribe((response) => {
      if (response != null) {
        this.googlePlace = new GooglePlace().deserialize(response);
        this.resetButtonActive = true;
        this.loadMarkers();
      } else {
        this.googlePlace = new GooglePlace();
        this.googlePlace.placeId = 'no_address';
      }
    });
  }

  removeImage(index: number) {
    this.model.mediaFiles.splice(index, 1);
  }

  addImage(event) {
    const fileList: FileList = event.target.files;
    if (fileList.length > 0) {
      const file: File = fileList[0];
      // upload file
      this.api
        .postDefiImage(file)
        .pipe(catchError((error) => Observable.throw(error)))
        .subscribe((data) => {
          this.model.mediaFiles.push(data);
          this.fileInput.nativeElement.value = '';
        });
    }
  }
}

@Component({
  selector: 'ngbd-modal-content',
  template: `
    <form (ngSubmit)="onSubmit()" #userForm="ngForm">
      <div class="modal-header">
        <h4 class="modal-title" *ngIf="newUser">Add new User</h4>
        <h4 class="modal-title" *ngIf="!newUser">Edit User</h4>
        <button
          type="button"
          class="close"
          aria-label="Close"
          (click)="activeModal.dismiss()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="form-group">
          <label for="ownerEmail">Email</label>
          <input
            type="text"
            class="form-control"
            id="userEmail"
            readonly
            [(ngModel)]="userModal.email"
            name="userEmail"
          />
        </div>
        <div class="form-group">
          <label for="ownerFirstName">Firstname</label>
          <input
            type="text"
            class="form-control"
            id="userFirstName"
            [(ngModel)]="userModal.firstName"
            name="userFirstName"
          />
        </div>
        <div class="form-group">
          <label for="ownerLastName">Lastname</label>
          <input
            type="text"
            class="form-control"
            id="userLastName"
            [(ngModel)]="userModal.lastName"
            name="userLastName"
          />
        </div>
        <div class="form-group">
          <label for="ownerPhone">Phone</label>
          <input
            type="text"
            class="form-control"
            id="userPhone"
            [(ngModel)]="userModal.phoneNumber"
            name="userPhone"
          />
        </div>
      </div>
      <div class="modal-footer">
        <button class="btn btn-success" type="submit">Save</button>
        <button type="button" class="btn btn-outline-dark" (click)="cancel()">
          Cancel
        </button>
      </div>
    </form>
  `,
})
export class NgbdModalContent {
  @Input() user: any;
  @Input() userMail: any;
  @Input() userDeputy: any;
  @Input() userDeputyMail: any;
  userObject;
  userModal;
  closeByClickingOutside = false;
  newUser = false;

  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService,
    private router: Router
  ) {}

  ngOnInit() {
    if (this.user) {
      this.userModal = { ...this.user };
      this.newUser = false;
    }

    if (this.userMail) {
      this.userModal = new Object();
      this.newUser = true;
      this.userModal.email = this.userMail;
    }

    if (this.userDeputy) {
      this.userModal = { ...this.userDeputy };
      this.newUser = false;
    }

    if (this.userDeputyMail) {
      this.userModal = new Object();
      this.newUser = true;
      this.userModal.email = this.userDeputyMail;
    }
  }

  onSubmit() {
    // set the form to submitted to avoid double-firing
    // this.submitted = true;

    if (this.newUser == false) {
      this.api.putRegisteredUser(this.userModal).subscribe((response) => {
        this.userObject = response;
        this.activeModal.close(this.userObject);
      });
    }
    // send changed to API and redirect back
    if (this.newUser == true) {
      this.api.postRegisteredUser(this.userModal).subscribe((response) => {
        this.userObject = response;
        this.activeModal.close(this.userObject);
      });
    }
  }

  cancel() {
    this.activeModal.dismiss();
  }
}

interface marker {
  lat: number;
  lng: number;
  label?: string;
  draggable: boolean;
}
