import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../services/index';

@Component({
  templateUrl: 'forgot-password.component.html',
})
export class ForgotPasswordComponent implements OnInit {
  model: any = {};
  loading = false;
  requestState: number;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    // start with status 1
    this.requestState = 1; // start
  }

  forgotPassword() {
    this.loading = true;
    this.authService.recover(this.model.username).subscribe(
      (data) => {
        if (data === true) {
          this.requestState = 2;
        } // OK
        else {
          this.requestState = 3; // error
          this.loading = false;
        }
      },
      (error) => {
        this.requestState = 3; // error
        this.loading = false;
      }
    );
  }
}
