import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { CookieService } from './cookie.storage';
import { GlobalEventsManager } from './global-events-manager';

@Injectable()
export class AuthService {
  private static _loginData;
  public static _token;
  expires;
  public id;
  public set loginData(ld: any) {
    AuthService._loginData = ld;
    if (ld && ld.access_token) {
      this.expires = ld['.expires'] && Date.parse(ld['.expires']);
      AuthService._token = ld.access_token;
      const expiration = new Date();
      expiration.setDate(expiration.getDate() + 365);
      this.cookieStorage.set('user', ld, expiration.toUTCString());
    } else {
      AuthService._token = null;
      this.expires = Date.now() - 1000000;
      console.log('remove user', ld);
      console.trace();
      this.cookieStorage.remove('user');
    }
  }

  public get loginData() {
    return AuthService._loginData;
  }

  public get token() {
    return AuthService._token;
  }

  public get isSuperAdmin() {
    if (this.loginData?.roles != null) {
      if (this.loginData.roles.indexOf('SuperAdmin') > -1) {
        return true;
      }
    }
    return false;
  }

  constructor(
    private cookieStorage: CookieService,
    private http: HttpClient,
    private router: Router,
    private globalEventsManager: GlobalEventsManager
  ) {
    const ld = cookieStorage.get('user');
    this.id = Math.floor(Math.random() * 10000);
    try {
      this.loginData = ld;
    } catch (e) {
      console.error('Error parsing token', this.loginData);
    }
    this.errorHandler = this.errorHandler.bind(this);
  }

  isLoggedIn() {
    // logged in? do not take expired session into account.
    // will be handled by http interceptor
    if (this.loginData) {
      return true;
    }
    return false;
  }

  login(username: string, password: string, handled: boolean = false) {
    const body = new URLSearchParams();
    body.set('username', username);
    body.set('password', password);
    body.set('client_id', 'CisaliCrossPlatformApp');
    body.set('client_secret', 'bykantan');
    body.set('grant_Type', 'password');

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    if (handled) {
      headers.append('Request-Handled', 'true');
    }
    const obs = this.http.post('/api/token', body.toString(), { headers }).pipe(
      map((response: Response) => {
        // response is the login token
        this.loginData = response;
        return this.loginData;
      })
    );
    if (!handled) {
      obs.pipe(catchError(this.errorHandler));
    }
    return obs;
  }

  refreshToken() {
    const body = new URLSearchParams();
    body.set('refresh_token', this.loginData.refresh_token);
    body.set('client_id', 'CisaliCrossPlatformApp');
    body.set('client_secret', 'bykantan');
    body.set('grant_Type', 'refresh_token');

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    // do not handle this request to avoid endless loops
    headers.append('Request-Handled', 'true');
    return this.http
      .post('/api/token', body.toString(), { headers })
      .pipe(
        tap((response: Response) => {
          this.loginData = response;
        })
      )
      .pipe(
        catchError((e) => {
          this.loginData = null;
          return EMPTY;
        })
      );
  }

  register(
    email: string,
    password: string,
    firstname,
    lastname,
    lang?,
    handled: boolean = false
  ) {
    const body = new URLSearchParams();
    body.set('email', email);
    body.set('firstname', firstname);
    body.set('lastname', lastname);
    body.set('password', password);
    if (lang) {
      body.set('lang', lang);
    }

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    if (handled) {
      headers.append('Request-Handled', 'true');
    }
    const obs = this.http.post('/api/account/register', body.toString(), {
      headers,
    });
    if (!handled) {
      obs.pipe(catchError(this.errorHandler));
    }
    return obs;
  }

  registerCheck(email: string) {
    const body = new URLSearchParams();
    body.set('email', email);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post<any>('/api/account/registercheck', body.toString(), { headers })
      .pipe(catchError(this.errorHandler));
  }

  recover(email: string, lang?) {
    // const body = new URLSearchParams();
    // body.set('email', email);
    // if (lang) {
    //  body.set('lang', lang);
    // }

    // const headers = new HttpHeaders();
    // headers.append('Content-Type', 'application/x-www-form-urlencoded');
    const model = { email };
    return this.http
      .post<any>('/api/account/getrecoveryemail', model)
      .pipe(catchError(this.errorHandler));
  }

  activate(userId: string, code: string) {
    const body = new URLSearchParams();
    body.set('UserId', userId);
    body.set('code', code);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post<any>('/api/account/activate', body.toString(), { headers })
      .pipe(catchError(this.errorHandler));
  }

  reset(email: string, code: string, password: string) {
    const body =
      'email=' +
      encodeURIComponent(email) +
      '&code=' +
      encodeURIComponent(code) +
      '&password=' +
      encodeURIComponent(password);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    headers.append('Request-Handled', 'true');
    return this.http
      .post<any>('/api/account/ResetPassword', body.toString(), { headers })
      .pipe(catchError(this.errorHandler));
  }

  resend(email: string) {
    const body = new URLSearchParams();
    body.set('email', email);

    const headers = new HttpHeaders();
    headers.append('Content-Type', 'application/x-www-form-urlencoded');
    return this.http
      .post<any>('/api/account/lostactivation', body.toString(), { headers })
      .pipe(catchError(this.errorHandler));
  }

  logout() {
    this.loginData = null;
    this.globalEventsManager.hideNavBar.emit(true);
    this.router.navigate(['/login']);
  }

  errorHandler(error) {
    if (error.json) {
      error = error.json();
    }
    const errorMessage = error.errorMessage || error.error || error.toString();
    return Observable.throw(
      error.json ? error.json().error : error.toString() || 'Serverfehler'
    );
  }
}
