import { Store } from '@ngrx/store';
import { of, throwError, Observable, combineLatest } from 'rxjs';
import { tap, switchMap, catchError, map } from 'rxjs/operators';
import { get, set, merge } from 'lodash';
import cookieStorage from '../services/cookie.storage';

export function getUser() {
  const user = cookieStorage.get('user');
  if (!user) {
    return null;
  }
  const expires = user['.expires'] && Date.parse(user['.expires']);
  if (expires < Date.now()) {
    return null;
  }
  return user;
}

export const inProgressType = (actionName) => `${actionName}_PENDING`;
export const successType = (actionName) => `${actionName}_SUCCESS`;
export const errorType = (actionName) => `${actionName}_ERROR`;
export const resetType = (actionName) => `${actionName}_RESET`;

export function asyncAction(store: Store<any>, type: string, obs$: Observable<any>, context?) {
  return of({}).pipe(
    tap(() => store.dispatch({ type, context, async: true, status: 'PENDING' })),
    switchMap(() => obs$),
    tap((data) => store.dispatch({ type: successType(type), payload: data, context, async: true, status: 'SUCCESS' })),
    catchError((err) => {
      store.dispatch({ type: errorType(type), payload: err, context, async: true, status: 'ERROR' });
      return throwError(err);
    })
  );
}

export interface AsyncState {
  result: any;
  pending: boolean;
  error: any;
}

export function asyncReducer(reducer) {
  return (state, action) => {
    const nextState = reducer(state, action);

    if (action.async) {
      let newSlice: AsyncState = {
        result: undefined,
        pending: false,
        error: undefined
      };

      const slice = get(nextState, action.context);

      switch (action.status) {
        case 'PENDING': {
          newSlice = {
            ...slice,
            result: null,
            pending: true,
            error: null
          };
          break;
        }

        case 'SUCCESS': {
          newSlice = {
            ...slice,
            pending: false,
            result: action.payload
          };
          break;
        }

        case 'ERROR': {
          newSlice = {
            ...slice,
            pending: false,
            error: action.payload
          };
          break;
        }
      }

      const slicedState = set({}, action.context, newSlice);

      return merge({}, nextState, slicedState);
    }

    return nextState;
  };
}

export function chainActions(...actions) {
  return actions.reduce((a, b) => {
    return a.pipe(
      switchMap((data) => combineLatest(b, of(data))),
      map((data: any) => {
        return data[1];
      })
    );
  });
}
